// Dependencies
import React from 'react'
import './styles.less'

// Components
import BeatLoader from 'react-spinners/BeatLoader'

// Assets
const LOGO_IMG = require('img/general/punkpost-logo.png')
const LOGO_IMG_WEBP = require('img/general/punkpost-logo.webp')


export default function RouterLoader() {
  return (
    <div className="RouterLoader">
      <div className="RouterLoader__Header">
        <picture>
          <source type="image/webp" srcSet={LOGO_IMG_WEBP} />
          <img src={LOGO_IMG} alt="brand" className="logo" />
        </picture>
      </div>
      <div className="RouterLoader__Container">
        <BeatLoader size={8} />
      </div>
    </div>
  )
}
