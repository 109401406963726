//
// -- CREATE Contact -- //
//
// Created by Jonatan Santa Cruz on 2017.
// Copyright 2023 Punkpost Inc.
//


// Base
import Parse from 'parse'
import dayjs from 'dayjs'

// Utils
import request from 'utils/request'

// Services
import { createEvent } from 'services/events'

// Constants
import {
  CREATE_VERIFIED_CONTACTS_URL,
  UPLOAD_CONTACTS_CSV_URL,
  VERIFY_IMPORTED_CONTACTS_URL
} from 'constants'
const Contact = Parse.Object.extend('Contacts')


/**
  * @function createContact
  * @param {object} contactData of contact
  * @param {object} profilePicture of contact
  * @param {boolean} isSender if contact is the User
  * @returns {Promise} contact data
**/
export const createContact = (
  contactData,
  profilePicture = null,
  isSender = false
) => {
  return new Promise((resolve, reject) => {
    const user = Parse.User.current()
    if (!user) {
      reject(null)
    } else {
      new Contact()
        .set({
          contactName: contactData.contactName,
          company: contactData.company,
          jobTitle: contactData.jobTitle,
          companyDepartment: contactData.companyDepartment,
          email: contactData.email,
          contactAddress: contactData.contactAddress,
          unit: contactData.unit,
          contactCity: contactData.contactCity,
          contactState: contactData.contactState,
          contactZip: contactData.contactZip,
          country: contactData.country,
          lastVerification: dayjs().toDate(),
          isSender: !!isSender,
          ownerId: user.id,
          owner: user,
          profilePic: profilePicture,
          punkposter: false,
          punkposterId: '-',
          received: 0,
          scheduled: 0,
          sent: 0
        })
        .save()
        .catch(error => reject(error))
        .then((newContact) => {
          const contacts = user.relation('contacts')
          contacts.add(newContact)
          user.save()
            .catch(error => reject(error))
            .then(() => {
              if (
                contactData.birthDay && contactData.birthDay.toLowerCase() !== 'day' &&
                contactData.birthMonth && contactData.birthMonth.toLowerCase() !== 'month'
              ) {
                // There's a birthday:
                const monthNumber = dayjs().month(contactData.birthMonth).format('M') - 1
                const dayNumber = parseInt(contactData.birthDay, 10)
                createEvent(newContact.getAttributes(), 'Birthday', monthNumber, dayNumber)
                  .catch(error => reject(error))
                  .then(() => resolve(newContact))
              } else {
                // No birthday.
                resolve(newContact)
              }
            })
        })
    }
  })
}


/**
  * @function duplicateContact
  * @param {object} contact of contact
  * @param {boolean} isSender if contact is the User
  * @returns {Promise} contact data
**/
export const duplicateContact = (contact, isSender = false) => {
  return new Promise((resolve, reject) => {
    if (!contact) {
      reject(null)
    }
    createContact(contact.getAttributes(), contact.getAttributes().profilePic, isSender)
      .catch(error => reject(error))
      .then(newContact => resolve(newContact))
  })
}


// API Functions

export const uploadContactsFile = file => {
  const body = new FormData()
  body.append('contacts', file)
  return request(UPLOAD_CONTACTS_CSV_URL, {
    method: 'POST',
    isUpload: true,
    body
  })
}


export const verifyImportedContacts = columns => {
  return request(VERIFY_IMPORTED_CONTACTS_URL, {
    method: 'POST',
    body: columns
  })
}


export const createVerifiedContacts = records => {
  return request(CREATE_VERIFIED_CONTACTS_URL, {
    method: 'POST',
    body: records
  })
}
