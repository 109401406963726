export const FETCH_INITIAL_DATA =
  'scenes/Project/ProjectSettings/fetch_initial_data'
export const UPDATE_PROJECT = 'scenes/Project/ProjectSettings/update_project'
export const UPDATE_PHOTOS = 'scenes/Project/ProjectSettings/update_photos'
export const UPDATE_CARDS = 'scenes/Project/ProjectSettings/update_cards'
export const UPDATE_NOTES = 'scenes/Project/ProjectSettings/update_notes'
export const REMOVE_CARD = 'scenes/Project/ProjectSettings/remove_card'
export const ADD_CARD = 'scenes/Project/ProjectSettings/add_card'
export const ADD_PHOTO = 'scenes/Project/ProjectSettings/add_photo'
export const REMOVE_PHOTO = 'scenes/Project/ProjectSettings/remove_photo'
export const UPDATE_PRICES = 'scenes/Project/ProjectSettings/update_prices'
export const RESET_STATE = 'scenes/Project/ProjectSettings/reset_state'
export const FETCH_SENDERS = 'scenes/Project/ProjectSettings/fetch_senders'
