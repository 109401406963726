import React from 'react'

import { Route, Redirect } from 'react-router-dom'

import { isAuthenticated } from 'utils/auth'

const defaultRender = ({ component: Component, ...props }) => (
  <Component {...props} />
)

export const LoginRequiredRoute = ({
  render = defaultRender,
  ...routeProps
}) => {
  const _render = props => {
    return !isAuthenticated() ? (
      <Redirect
        to={{
          pathname: '/',
          state: { from: props.location }
        }}
      />
    ) : (
      render(props)
    )
  }

  return <Route {...routeProps} render={_render} />
}

/*
  * Returns url params by context router
  * @param {object} router from context -> router
  * @returns {object} params from router params
*/
export const getRouterParams = router => {
  return router.match.params
}
