//
// -- EndCallToAction Container-- //
//
// Created by Santiago Prieto on Nov 9 2022.
// Copyright 2023 Punkpost Inc.
//


// Base
import React from 'react'
import BaseComponent from 'utils/BaseComponent'
import './style.less'

// Constants
import EmailSignupForm from 'components/Landing/EmailSignupForm'


// Base Component
export default class EndCallToAction extends BaseComponent {
  render() {
    return (
      <div className="EndCallToAction">
        <EmailSignupForm
          autoFocus={false}
        />
      </div>
    )
  }
}
