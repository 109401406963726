// -- Notification Component -- //

import React from 'react'
import { connect } from 'react-redux'
import BaseComponent from 'utils/BaseComponent'
import './styles.less'

// Reducer Actions
import { hideNotificaction } from './actions'


// Base Component
export class Notificaction extends BaseComponent {
  componentDidMount() {
    this.timer = setInterval(() => {
      this.props.hideNotificaction()
    }, 3000)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }


  // Render

  render() {
    const notification = this.props
    let containerClass = 'NotificationAuthentication'
    switch (notification.type) {
      case 'primary':
        containerClass = 'NotificationCards'
        break
      case 'tertiary':
        containerClass = 'NotificationSmall'
        break
      default:
        containerClass = 'NotificationAuthentication'
    }
    return (
      <div id="Notification" className={containerClass}>
        <div className={`Container Notification-${notification.visible}`}>
          <p>{notification.text}</p>
        </div>
      </div>
    )
  }
}


// Reducers

const mapStateToProps = state => {
  return {
    visible: state.notificationHandler.get('visible'),
    text: state.notificationHandler.get('text'),
    type: state.notificationHandler.get('type')
  }
}

const mapDispatchToProps = dispatch => {
  return {
    hideNotificaction: () => dispatch(hideNotificaction())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notificaction)
