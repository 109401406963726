//
// -- fetch CLIENT NOTIFICATIONS -- //
//


// Base
import Parse from 'parse'

// Reference Objects
const ClientNotification = Parse.Object.extend('ClientNotification')


export const fetchClientNotifications = (limit = 30) => {
  const user = Parse.User.current()
  if (!user) {
    return null
  }
  let query = new Parse.Query(ClientNotification)
    .equalTo('recipient_user', user)
    .notEqualTo('is_active', false)
    .descending('createdAt')
    .limit(limit)
    .include([
      'sender_user', 'sender_user.myContact', 'sender_artist',
      'comment', 'post'
    ])
  return query.find()
}
