//
// -- FETCH Events & Holidays -- //
//
// Created by Jonatan Santa Cruz on 2017.
// Copyright 2023 Punkpost Inc.
//


// Base
import Parse from 'parse'
import dayjs from 'dayjs'

// Constants
const Contact = Parse.Object.extend('Contacts')
const Event = Parse.Object.extend('Event')
const Holiday = Parse.Object.extend('Holiday')
const EVENT_POINTERS = [
  'contact', 'contact.birthday', 'contact.birthday.holiday',
  'holiday', 'holiday.collection',
  'workingPost', 'workingPost.cardPointer', 'workingPost.cardPointer.price',
  'workingPost.senderContact', 'workingPost.senderUser',
  'workingPost.recipientUser', 'workingPost.recipientContact',
  'workingPost.writerPointer', 'workingPost.writerPointer.contact',
  'workingPost.cardsCollection'
]


/**
  * @function getHolidays
  * @param {boolean} showBasic of event
  * @param {string} relationship of contact
  * @returns {Promise} holidays data
**/
export const getHolidays = (showBasic, relationship = null) => {
  let query = new Parse.Query(Holiday)
    .descending('popularity')
    .notEqualTo('is_active', false)
  if (showBasic && showBasic === true) {
    query.limit(2)
  }
  if (relationship) {
    query.contains('relationships', relationship)
  }
  return query.find()
}


/**
  * @function getEventData
  * @param {string} event_id of event
  * @returns {Promise} event data
**/
export const getEventData = event_id => {
  const query = new Parse.Query(Event)
    .include(EVENT_POINTERS)
  return query.get(event_id)
}


/**
  * @function getEvent
  * @param {string} event_id of event
  * @returns {Promise} event data
**/
export const getEvent = event_id => {
  const query = new Parse.Query(Event)
    .include(EVENT_POINTERS)
  return query.get(event_id)
}


/**
  * @function getEventsForContact
  * @param {string} contact_id of event
  * @returns {Promise} events data
**/
export const getEventsForContact = contact_id => {
  const currentUser = Parse.User.current()
  if (!currentUser) {
    return new Promise((resolve, reject) => {
      return reject()
    })
  }
  const contactReference = new Contact()
    .set('objectId', contact_id)

  const query = new Parse.Query(Event)
    .equalTo('user', currentUser)
    .equalTo('contact', contactReference)
    .notEqualTo('is_active', false)
    .include(EVENT_POINTERS)
    .ascending('updatedAt')
  return query.find()
}


/**
  * @function getUpcomingEvents
  * @param {number} limit of events to get.
  * @param {number} daysOut from today.
  * @returns {Promise} events data
**/
export const getUpcomingEvents = (limit = 5, daysOut = 0) => {
  return new Promise((resolve, reject) => {
    const month = dayjs().month() + 1
    let day = 1
    if (daysOut > 0) {
      day = dayjs().get('date') + daysOut
    }
    let nextMonth = month + 1
    if (nextMonth >= 13) {
      nextMonth = 1
    }
    const currentUser = Parse.User.current()
    if (!currentUser) {
      reject()
    } else {
      const thisMonthQuery = new Parse.Query(Event)
        .equalTo('user', currentUser)
        .notEqualTo('is_active', false)
        .exists('contact')
        .exists('holiday')
        .exclude('user')
        .equalTo('month', month)
        .greaterThan('day', day)
        .ascending('month')
        .addAscending('day')

      const remainderOfYearQuery = new Parse.Query(Event)
        .equalTo('user', currentUser)
        .notEqualTo('is_active', false)
        .exists('contact')
        .exists('holiday')
        .exclude('user')
        .greaterThan('month', month)
        .ascending('month')
        .addAscending('day')

      Parse.Query.or(
        thisMonthQuery,
        remainderOfYearQuery
      )
        .limit(limit)
        .ascending('month')
        .addAscending('day')
        .exists('contact')
        .exists('holiday')
        .include(EVENT_POINTERS)
        .find()
        .catch(error => {
          return reject(error)
        })
        .then(_eventsThisYear => {
          if (_eventsThisYear.length >= limit) {
            resolve(_eventsThisYear)
          } else {
            new Parse.Query(Event)
              .equalTo('user', currentUser)
              .notEqualTo('is_active', false)
              .exists('contact')
              .exists('holiday')
              .exclude('user')
              .greaterThanOrEqualTo('month', 1)
              .lessThan('month', month)
              .limit(limit - _eventsThisYear.length)
              .ascending('month')
              .addAscending('day')
              .include(EVENT_POINTERS)
              .find()
              .catch(error => {
                console.warn(error)
                reject(error)
              })
              .then(_eventsNextYear => {
                resolve(_eventsThisYear.concat(_eventsNextYear))
              })
          }
        })
    }
  })
}
