export const USA_SYNONIMS = [
  'UNITEDSTATES', 'UNITEDSTATE', 'UNITESSATES', 'UNITEDSTATED', 'UNITEDSATES', 'USA',
  'US', 'USOFA', 'UNITEDSTATESOFAMERICA', 'U.S.A.', 'U.S.', 'UNITED.STATES',
  'UNITED.STATES.AMERICA', `UNITEDSTATESOFAMERICA'S`, 'AMERICA', 'ESTADOSUNIDOS',
  'ESTADOSUNIDOSDEAMERICA', 'ESTADOSUNIDOSAMERICANOS', 'ESTADOS.UNIDOS',
  'MERICA', 'EEUUAA', 'ESTADOSUNIDOSDOAMÉRICA', 'ESTADOSUNIDOSDOAMERICA', 'EUA', 'ETATSUNIS',
  'ÉTATSUNIS', 'ÉTATS-UNIS', 'VEREINIGTESTAATEN', 'VERENIGDE STATEN', 'FORENDESTATER',
  'FÖRENTASTATERNA', 'FORENTESTATER', 'STATIUNITI', 'STANYZJEDNOCZONE', 'THEUNITEDSTATES',
  'THEUS', 'THEUSA', 'THEUNITEDSTATESOFAMERICA', 'UNIREDSTATES', 'UNITDSTATES',
  'UITEDSTATES', 'UNITEDSTATS', 'PUERTORICO', 'UNITESTATES', 'UINTEDSTATES',
  'U..S..A..', 'U.S.', 'U..S..'
]

export const isInternational = country => {
  const _country = country.toUpperCase().replace(/\s/g, '')
  const _isInternational = !USA_SYNONIMS.includes(_country)
  return _isInternational
}
