//
// -- HeroContainer for New User Main Landing Page-- //
//
// Created by Santiago Prieto on May 2022.
// Copyright 2023 Punkpost Inc.
//


// Base
import React from 'react'
import BaseComponent from 'utils/BaseComponent'
import './styles.less'

// Components
import HeroSection from 'components/Landing/HeroSection'
import EmailSignupForm from 'components/Landing/EmailSignupForm'

// Base Component
export default class HeroContainer extends BaseComponent {
  render() {
    return (
      <div className="HeroContainer">
        <HeroSection>
          <EmailSignupForm/>
        </HeroSection>
      </div>
    )
  }
}
