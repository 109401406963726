
// Base
import Parse from 'parse'
import { history } from 'app'

// Utils
import request from 'utils/request'

// Services
import { setClientProfile } from 'services/profile'
import { USER_WELCOME_EMAIL_URL } from 'constants'

// Constants
const Contact = Parse.Object.extend('Contacts')
const Profile = Parse.Object.extend('Profile')
const User = Parse.Object.extend('User')


/**
 * Check if there is a current user logged in.
 * @function isAuthenticated
 * @return {Bool}
**/
export const isAuthenticated = () => {
  const currentUser = Parse.User.current()
  return !!currentUser
}


/**
 * Check if cuerrent User has myContact
 * @function hasMyContact
 * @return {Bool}
**/
export const hasMyContact = () => {
  const currentUser = Parse.User.current()
  if (!currentUser) {
    return true
  }
  const contactName = currentUser.get('myContact').get('contactName')
  const contactAddress = currentUser.get('myContact').get('contactAddress')
  return !!contactName && !!contactAddress
}


/*
 * Close session, clean up localStorage and redirects to Home.
*/
export const logout = () => {
  Parse.User.logOut().then(() => history.push('/'))
}


/*
 * tries to log in an user with given email & passsword
 */
export const login = (email, password) => {
  return new Promise((resolve, reject) => {
    Parse.User.logIn(email, password)
      .catch(reject)
      .then(user => {
        if (!user) {
          reject()
        } else {
          // User Platforms
          if (user.get('platforms')) {
            if (!user.get('platforms').includes('web')) {
              user.set({ platforms: [...user.get('platforms'), 'web'] })
            }
          } else {
            user.set({ platforms: ['web'] })
          }
          user.save()

          if (!user.get('profile') || !user.get('myContact')) {
            const contact = new Contact()

            setClientProfile()
              .catch(reject)
              .then(profile => {
                if (!user.get('profile')) {
                  user.set('profile', profile)
                }
                if (!user.get('myContact')) {
                  user.set('myContact', contact)
                }
                user.save()
                  .then(resolve)
                  .catch(reject)
              })
          } else {
            resolve(user)
          }
        }
      })
  })
}


/*
 * Sends request to send password recovery email for given email
 */
export const recoverPassword = email => {
  return Parse.User.requestPasswordReset(email)
}


/*
 * Creates a new Punkpost account with given email & password
 */
export const signup = (email, password) => {
  return new Promise((resolve, reject) => {
    const user = new Parse.User()
    const contact = new Contact()

    // Setup initial Parse profile information.
    const profile = new Profile()
    profile.set({
      credits: 0,
      order_amount: 0,
      billingMethod: 'at checkout'
    })

    Promise.all([profile.save(), contact.save()])
      .catch(reject)
      .then(([_profile, _contact]) => {
        // Setup initial Parse user information.
        user.set({
          username: email,
          password: password,
          email: email,
          myContactId: _contact.getid,
          myContact: _contact,
          profile: _profile,
          platforms: ['web'],
          sessions: 1,
          cardsOrdered: 0
        })

        user.signUp()
          .catch(reject)
          .then(() => {
            request(USER_WELCOME_EMAIL_URL(user.id), { method: 'POST' })
            resolve()
          })
      })
  })
}


/*
 * Retrieve user profile (myContact wut!?) from Parse
 * Returns new Promise
 */
export const retrieveUserProfile = () => {
  return new Promise((resolve, reject) => {
    const currentUser = Parse.User.current()
    if (!currentUser) {
      reject()
    }
    const query = new Parse.Query(User)
      .include('myContact')
      .include('profile')
      .equalTo('objectId', currentUser.id)

    query.first()
      .catch(error => (reject(error)))
      .then(user => {
        if (!user) {
          reject()
        } else {
          resolve({
            contact: user.attributes.myContact.getAttributes(),
            profile: user.attributes.profile.getAttributes()
          })
        }
      })
  })
}


export const getCurrentUserToken = () => {
  const user = Parse.User.current()
  return user ? user.getSessionToken() : null
}


// Verify email isn't already a user.
export const verifyEmailIsUser = email => {
    const query = new Parse.Query(User)
      .equalTo('username', email)

    return query.first()
}
