// Cache Busting Functions
// NOTE: For busting CSS when there's a new app version.


import React from 'react'
import packageJson from '../package.json'
global.appVersion = packageJson.version


// version from `meta.json` - first param
// version in bundle file - second param
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g)
  const versionsB = versionB.split(/\./g)
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift())
    const b = Number(versionsB.shift())
    if (a === b) {
      continue
    }
    if (a > b) {
      continue
    }
    return b
  }
  return false
}

class CacheBuster extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: (reloadAfterClear = true) => {
        console.log('Clearing cache and hard reloading...')
        if (caches in window) {
          caches.keys().then(async function (names) {
            await Promise.all(names.map(name => caches.delete(name)))
          })
          // delete browser cache and hard reload.
          if (reloadAfterClear) {
            window.location.reload()
          }
        }
      }
    }
  }

  componentDidMount() {
    fetch('/meta.json')
      .catch(error => console.warn(error))
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version
        const currentVersion = global.appVersion
        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion)
        if (shouldForceRefresh) {
          console.warn(`New version ${latestVersion} found. Should force refresh.`)
          this.setState({ loading: false, isLatestVersion: false })
        } else {
          console.log(`Punkpost version - ${latestVersion}.`)
          this.setState({ loading: false, isLatestVersion: true })
        }
      })
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state
    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload })
  }
}

export default CacheBuster
