//
// -- DELETE Contacts -- //
//
// Created by Jonatan Santa Cruz on 2017.
// Copyright 2023 Punkpost Inc.
//


// Base
import Parse from 'parse'

// Services
import { getEventsForContact } from 'services/events'
// Constants
const Contact = Parse.Object.extend('Contacts')


export const removeContactById = contact_id => {
  return new Promise((resolve, reject) => {
    if (!contact_id) {
      reject()
    }
    const contact = new Contact()
      .set({
        objectId: contact_id,
        is_active: false
      })
    contact.save()
      .catch(error => reject(error))
      .then(() => {
        getEventsForContact(contact_id)
          .catch(resolve())
          .then(events => {
            if (!events) {
              return resolve()
            }
            events.map(_event => {
              _event.set({
                is_active: false,
                should_notify: false
              })
            })
            return Parse.Object.saveAll(events)
          })
      })
  })
}

export const removeContact = contact => {
  return removeContactById(contact.id)
}


export const removeContactsByIds = (contactIds = []) => {
  const contactsPromises = []
  contactIds.map(contactId => {
    contactsPromises.push(removeContactById(contactId))
  })
  return Promise.all(contactsPromises)
}
