//
// -- HeroReview Atom for New User Main Landing-- //
//
// Created by Santiago Prieto on May 2022.
// Copyright 2023 Punkpost Inc.
//


// Base
import React from 'react'
import BaseComponent from 'utils/BaseComponent'
import './styles.less'

// Constants
const TRUST_BODY = 'Over 500,000 custom cards created.'
const APPSTORE = '4.8 • 2.8K AppStore Ratings'
const REVIEW = {
  quote: `"So incredibly beautiful. I love sending Punkpost
  cards just to see what the artists come up with!"`,
  author: `Gloria B.`
}

// Base Component
export default class HeroReview extends BaseComponent {
  render() {
    return (
      <div className="HeroReview">
        <div className="HeroReview__Trust">
          {TRUST_BODY}
        </div>
        <div className="HeroReview__Header">
          <p className="HeroReview__Header__Stars">
            &#10029;&#10029;&#10029;&#10029;&#10029;
          </p>
          <p className="HeroReview__Header__AppStore">
            {APPSTORE}
          </p>
        </div>
        <div className="HeroReview__Quote">
          <p className="HeroReview__Quote__Text">
            {REVIEW.quote}
          </p>
          <p className="HeroReview__Quote__Author">
            {REVIEW.author}
          </p>
        </div>
      </div>
    )
  }
}
