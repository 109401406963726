// -- Account Reducer -- //

// Base
import { fromJS } from 'immutable'
import Validator from 'validatorjs'

// Constants
import {
  RESET_DATA,
  TOGGLE_LOADING_CONTACTS,
  UPDATE_ACCOUNT,
  UPDATE_CONTACTS,
  UPDATE_NEW_NOTIFICATIONS
} from './constants'


/**
  * @constant Immutable initial state
  * (https://facebook.github.io/immutable-js/docs/#/fromJS)
**/
export const initialState = fromJS({
  isAccountComplete: false,
  contact: {},
  profile: {},
  contacts: [],
  isLoadingContacts: false,
  newNotifications: 0
})


/**
  * Validate if account data is complete (https://github.com/skaterdav85/validatorjs)
  * @param {contact} contact user data (object).
  * @param {profile} profile user data (object).
  * @return {validator} if the contact is valid (boolean).
**/
const isAccountComplete = (contact, profile) => {
  const VALIDATION_RULES = {
    billingMethod: 'string',
    businessType: 'string',
    company: 'string',
    contactName: 'string|required',
    contactAddress: 'string|required',
    apt_unit_floor: 'string',
    contactCity: 'string',
    contactState: 'string',
    contactZip: 'string'
  }

  const account = { ...contact, ...profile }
  const validator = new Validator(account, VALIDATION_RULES)
  return validator.passes()
}


/**
  * Reducer of account data
  * @param {state} state of reducer
  * (https://facebook.github.io/immutable-js/docs/#/set)
  * (https://facebook.github.io/immutable-js/docs/#/merge)
  * @param {string} action for account reducer (https://redux.js.org/docs/basics/Actions.html)
  * @return {state} new state of reducer
**/
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_DATA:
      return state.merge(fromJS({
        isAccountComplete: false,
        contact: {},
        profile: {},
        contacts: [],
        isLoadingContacts: false,
        newNotifications: 0
      }))

    case TOGGLE_LOADING_CONTACTS:
        return state.set('isLoadingContacts', action.isLoadingContacts)

    case UPDATE_CONTACTS:
      return state.set('contacts', fromJS(action.contacts))

    case UPDATE_NEW_NOTIFICATIONS:
      return state.set('newNotifications', fromJS(action.newNotifications))

    case UPDATE_ACCOUNT:
      return state
        .set('contact', fromJS(action.contact))
        .set('profile', fromJS(action.profile))
        .set('isAccountComplete', isAccountComplete(action.contact, action.profile))

    default:
      return state
  }
}
