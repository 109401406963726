// -- FETCH -- //

// Base
import Parse from 'parse'

// Utils
import request from 'utils/request'

// Constants
import { USER_CARDS_URL } from 'constants'
const User = Parse.Object.extend('User')


/**
  * Retrieve current user data
  * @function getUserData
  * @returns {Promise} current user data
*/
export const getUserData = () => {
  const currentUser = Parse.User.current()
  const query = new Parse.Query(User)
    .include(['profile', 'myContact'])
  return query.get(currentUser.id)
}


export const getUserAttributes = () => {
  return Parse.User.current() && Parse.User.current().getAttributes()
}


export const fetchCards = () => {
  const user = Parse.User.current()
  if (!user) {
    return null
  }
  return request(USER_CARDS_URL(user.id))
}


export const fetchDefaultCard = () => {
  return Parse.User.current().getAttributes().defaultCard
}


export const fetchTotalUserCredits = () => {
  let currentUser = Parse.User.current()
  if (!currentUser) {
    return 0
  }
  const profile = currentUser.get('profile')
  const profileCredits = profile.get('credits')
  return profileCredits ? profileCredits : 0
}

export const fetchProfile = () => {
  let currentUser = Parse.User.current()
  if (!currentUser) {
    return null
  }
  const profile = currentUser.get('profile')
  return profile
}

export const fetchDefaultSender = () => {
  return Parse.User.current() && Parse.User.current().attributes.myContact
    ? Parse.User.current().attributes.myContact.getAttributes()
    : null
}

export const verifyHasSentCards = () => {
  const profile = fetchProfile()
  if (!profile) {
    return false
  }
  return !!profile.getAttributes().last_sent
}

export const verifyUserHasStripeId = () => {
  let user = Parse.User.current()
  if (!user) {
    return false
  }
  return !!user.attributes.stripeId
}

export const getUserDidFinishTour = () => {
  let user = Parse.User.current()
  if (!user) {
    return null
  }
  return user.attributes.didFinishTour
}

export const getUserDidFinishProjectsTour = () => {
  let user = Parse.User.current()
  if (!user) {
    return null
  }
  return user.attributes.didFinishProjectsTour
}
