//
// -- PageAlert Atom -- //
//
// Created by Santiago Prieto on April 2020.
// Copyright 2023 Punkpost Inc.
//


// Base
import React from 'react'
import BaseComponent from 'utils/BaseComponent'
import Parse from 'parse'
import './styles.less'

// Constants
const HEADER_PREFIX = `Because of high order volume, the expected mail time for new cards is
currently`
const HEADER_SUFFIX = 'days.'
const BODY = `We are doing our best to fulfill your orders as fast as we can. We know how important
these cards are for you and your people. We also know how much your recipient will appreciate their
card when it arrives. Thank you for your continued support!`


// Base Component
export default class PageAlert extends BaseComponent {
  render() {
    const CONFIG_PREP_DAYS = Parse.Config.current() ? Parse.Config.current().get('prepDays') : 1
    return (
      <div className="PageAlert">
        <div className="PageAlert__Container">
          <h2>
            {`${HEADER_PREFIX} ${CONFIG_PREP_DAYS} to ${CONFIG_PREP_DAYS + 1} ${HEADER_SUFFIX}`}
          </h2>
          <p>{BODY}</p>
        </div>
      </div>
    )
  }
}
