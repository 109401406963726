//
// -- FAVORITE Cards -- //
//


// Dependencies
import Parse from 'parse'

// Parse Objects
const Card = Parse.Object.extend('Cards')
const FavoriteCard = Parse.Object.extend('FavoriteCard')


/**
  * Verify if the card is already a user favorite
  * @function isFavoriteCard
  * @param {string} cardId of Card
  * @returns {object} if first result is available
**/
export const isFavoriteCard = cardId => {
  const user = Parse.User.current()
  if (!user) {
    return Promise.reject()
  }
  let card = new Card()
  card.id = cardId

  let query = new Parse.Query(FavoriteCard)
    .equalTo('user', user)
    .equalTo('card', card)
    .notEqualTo('is_active', false)

  return query.first(null)
}


/**
  * Assign card as favorite
  * @function putCardAsFavorite
  * @param {string} cardId of Card
  * @returns {ParseObject} New FavoriteCard
**/
export const putCardAsFavorite = cardId => {
  const user = Parse.User.current()
  if (!user || !cardId) {
    return Promise.reject()
  }
  let card = new Card()
    .set('objectId', cardId)

  let favorite = new FavoriteCard()
    .set({
      user: user,
      card: card
    })
    .set('is_active', true)

  return favorite.save()
}


/**
  * Retrieve FavoriteCards
  * @function queryFavoriteCards
  * @returns {Promise} favoriteCard data
**/
export const queryFavoriteCards = () => {
  const user = Parse.User.current()
  if (!user) {
    return Promise.reject()
  }
  let query = new Parse.Query(FavoriteCard)
    .equalTo('user', user)
    .notEqualTo('is_active', false)
    .descending('createdAt')
    .include(['card', 'card.price', 'card.cardArtist'])


  return query.find()
}


/**
  * Delete card as favorite
  * @function deleteCardAsFavorite
  * @param {string} favoriteCardId of Card
  * @returns {void} element deleted
**/
export const deleteCardAsFavorite = favoriteCardId => {
  let favorite = new FavoriteCard()
    .set('objectId', favoriteCardId)
    .set('is_active', false)
  return favorite.save()
}
