//
// -- Projects SubRoutes -- //
//
// Created on 2017
// Copyright Punkpost Inc. 2019
//


// Base
import { lazy } from 'react'

// Project Scenes
const ProjectDetail = lazy(() => import(
  /* webpackChunkName: "ProjectDetail" */
  'scenes/Project/ProjectDetail'
))
const ProjectSettings = lazy(() => import(
  /* webpackChunkName: "ProjectSettings" */
  'scenes/Project/ProjectSettings'
))

// Import Contact Scenes
const ImportContacts = lazy(() => import(
  /* webpackChunkName: "ImportContacts" */
  'scenes/Contact/ImportContacts'
))


// Routes
export default {
  detail: {
    path: '/:id',
    component: ProjectDetail
  },
  import: {
    path: '/:id/import',
    component: ImportContacts
  },
  settings: {
    path: '/:id/settings',
    component: ProjectSettings
  },
  new_settings: {
    path: '/settings/new',
    component: ProjectSettings
  }
}
