// -- Account Reducer Type Constants -- //

// Reset
export const RESET_DATA = 'reducers/global/account/reset_data'

// Update
export const UPDATE_ACCOUNT = 'reducers/global/account/update_account'
export const TOGGLE_LOADING_CONTACTS = 'reducer/global/account/toggle_loading_contacts'
export const UPDATE_CONTACTS = 'reducer/global/account/update_contacts'
export const UPDATE_NEW_NOTIFICATIONS = 'reducers/global/account/update_new_notifications'
