//
// -- UPDATE Cards -- //
//


// Base
import Parse from 'parse'

const CardCollectionItem = new Parse.Object('CardCollectionItem')


export const incrementCardCollectionItemClicks = cardCollectionItemId => {
  const cardCollectionItem = new CardCollectionItem()
    .set('objectId', cardCollectionItemId)
    .increment('clicks')
  return cardCollectionItem.save()
}


export const incrementCardsSent = (card, amount) => {
  if (!card || !card.id) {
    return null
  }
  const parse_card = new Parse.Object('Cards')
    .set('objectId', card.id)
    .increment('sent', amount)
    .increment('stock', -amount)

  if (card.stock <= 1) {
    parse_card.set('status', false)
  }
  return parse_card
}


export const batchIncrementCardsSent = (cardsToUpdate) => {
  return new Promise((resolve, reject) => {
    if (!cardsToUpdate || cardsToUpdate.length === 0) {
      reject()
    }
    const objectsToSave = []
    const requests = cardsToUpdate.map(update => {
      objectsToSave.push(incrementCardsSent(update.card, update.amount))
    })
    return Promise.all(requests).then(() => {
      Parse.Object.saveAll(objectsToSave)
        .catch(error => {
          console.log(error)
          resolve()
        })
        .then(() => resolve())
    })
  })
}


export const addCardToProject = (projectId, cardId) => {
  const project = new Parse.Object('Project')
    .set('objectId', projectId)
  const card = new Parse.Object('Cards')
    .set('objectId', cardId)
  const relation = project.relation('cards')
  relation.add(card)
  return project.save()
}


export const removeCardFromProject = (projectId, cardId) => {
  const project = new Parse.Object('Project')
    .set('objectId', projectId)
  const card = new Parse.Object('Cards')
    .set('objectId', cardId)
  const relation = project.relation('cards')
  relation.remove(card)
  return project.save()
}
