// -- Project Reducer -- //

// Base
import { fromJS } from 'immutable'

// Constants
import { SET_SEPECIFIC_SENT } from './constants'


export const initialState = fromJS({
  specificSent: ''
})


export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SEPECIFIC_SENT:
      return state.set('specificSent', action.id)
    default:
      return state
  }
}
