export const PUNKPOST_BENEFITS = [
  {
    header_a: 'Personal Mail Made Easy',
    header_b: 'Send a Card Worth Keeping',
    header_c: 'You Type, We Handwrite',
    body: `You type your message and a real-life
      handwriting artist writes your card and mails it for you.`,
    icon: require('img/promotion/punkpost-icon-benefit-01.webp'),
    png: require('img/promotion/punkpost-icon-benefit-01.png')
  },
  {
    header_a: 'Never Buy Stamps Again',
    header_b: 'We Got it From Here',
    header_c: 'Stamped and Mailed for You',
    body: `We do it all, and that includes adding a cool
      stamp and sending you photos before dropping it in the mail.`,
    icon: require('img/promotion/punkpost-icon-benefit-02.webp'),
    png: require('img/promotion/punkpost-icon-benefit-02.png')
  },
  {
    header_a: 'Starting at $5 Per Card',
    header_b: 'Add Gifts, Confetti and Photos',
    header_c: 'Hundreds of Exclusive of Cards',
    body: `The base price gets you a card, handwritten message,
      addressed envelope, stamp and delivery.`,
    icon: require('img/promotion/punkpost-icon-benefit-03.webp'),
    png: require('img/promotion/punkpost-icon-benefit-03.png')
  }
]

export const ABOUT_PUNKPOST = `Punkpost sends beautifully handwritten cards, mailed by artists for
you. Customers place orders via the Punkpost iOS or online platform in the amount of time it takes
to send a text. From there, one of our 100+ U.S.-based handwriting artists get to work, turning a
typed message into a custom, handwritten design on the card of your choosing, and it’s in the
mail within 24 hours. Punkpost is your easy access for creating and managing happy moments. 
For more info or to start sending love today, visit Punkpost.com, follow us @PunkpostCo
or download the app for iOS.`
