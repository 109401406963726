//
// -- Routes -- //
//
// Created on 2017
// Copyright 2023 Punkpost Inc.
//


// Base
import React, { Suspense, lazy } from 'react'
import { Route as ReactRoute, Switch } from 'react-router-dom'
import _map from 'lodash/map'

// Utils
import { LoginRequiredRoute } from 'utils/router'

// Components
import RouterLoader from 'components/Views/Loader'


//
// -- Containers -- //
//

const App = lazy(() => import(
  /* webpackChunkName: "AppContainer" */
  'containers/App'
))
const Authentication = lazy(() => import(
  /* webpackChunkName: "AuthContainer" */
  'containers/Authentication'
))


//
// -- Scenes -- //
//


// Landing
import MainLanding from 'scenes/Landing/MainLanding'

const AuthenticatedLanding = lazy(() => import(
  /* webpackChunkName: "AuthenticatedLanding" */
  'scenes/Landing/AuthenticatedLanding'
))
const BatchesLanding = lazy(() => import(
  /* webpackChunkName: "BatchesLanding" */
  'scenes/Landing/BatchesLanding'
))
const BusinessLanding = lazy(() => import(
  /* webpackChunkName: "BusinessLanding" */
  'scenes/Landing/BusinessLanding'
))
const WeddingsLanding = lazy(() => import(
  /* webpackChunkName: "WeddingsLanding" */
  'scenes/Landing/WeddingsLanding'
))


// Authentication
const SignupScene = lazy(() => import(
  /* webpackChunkName: "SignupScene" */
  'scenes/Authentication/Signup'
))
const EnterPasswordScene = lazy(() => import(
  /* webpackChunkName: "EnterPasswordScene" */
  'scenes/Authentication/EnterPassword'
))
const SignInScene = lazy(() => import(
  /* webpackChunkName: "SignInScene" */
  'scenes/Authentication/SignIn'
))


// Contacts
const RequestAddress = lazy(() => import(
  /* webpackChunkName: "RequestAddress" */
  'scenes/Contact/RequestAddress'
))
const AddressBook = lazy(() => import(
  /* webpackChunkName: "AddressBook" */
  'scenes/Contact/AddressBook'
))
const ImportContacts = lazy(() => import(
  /* webpackChunkName: "ImportContacts" */
  'scenes/Contact/ImportContacts'
))


// Project
const ProjectsListScene = lazy(() => import(
  /* webpackChunkName: "ProjectsListScene" */
  'scenes/Project/ProjectsListScene'
))


//
// -- Post Scenes -- //
//

// Outbox
const Outbox = lazy(() => import(
  /* webpackChunkName: "Outbox" */
  'scenes/Post/Outbox'
))
const SentDetail = lazy(() => import(
  /* webpackChunkName: "SentDetail" */
  'scenes/Post/SentDetail'
))
// Recipient
const ConfirmPostReceived = lazy(() => import(
  /* webpackChunkName: "ConfirmPostReceived" */
  'scenes/Post/Recipient/ConfirmPostReceived'
))
const DenyPostReceived = lazy(() => import(
  /* webpackChunkName: "DenyPostReceived" */
  'scenes/Post/Recipient/DenyPostReceived'
))


//
// -- Card Scenes -- //
//

const CardData = lazy(() => import(
  /* webpackChunkName: "CardData" */
  'scenes/Card/Data'
))
const CardShop = lazy(() => import(
  /* webpackChunkName: "CardShop" */
  'scenes/Card/CardShop'
))
const Genres = lazy(() => import(
  /* webpackChunkName: "Genres" */
  'scenes/Card/Genres'
))


//
// -- Event Scenes -- //
//

const EventDetail = lazy(() => import(
  /* webpackChunkName: "EventDetail" */
  'scenes/Event/EventDetail'
))


//
// -- General Scenes -- //
//

const ArtistApplication = lazy(() => import(
  /* webpackChunkName: "ArtistApplication" */
  'scenes/General/ArtistApplication'
))
const ContactUs = lazy(() => import(
  /* webpackChunkName: "ContactUs" */
  'scenes/General/ContactUs'
))
const CorporateNewsletterSignupScene = lazy(() => import(
  /* webpackChunkName: "CorporateNewsletterSignupScene" */
  'scenes/General/NewsletterSignup/Corporate'
))
const GeneralNewsletterSignup = lazy(() => import(
  /* webpackChunkName: "GeneralNewsletterSignup" */
  'scenes/General/NewsletterSignup/General'
))
const HolidayCardsRequest = lazy(() => import(
  /* webpackChunkName: "HolidayCardsRequest" */
  'scenes/General/HolidayCardsRequest'
))
const NotFound = lazy(() => import(
  /* webpackChunkName: "NotFound" */
  'scenes/General/NotFound'
))
const PricingScene = lazy(() => import(
  /* webpackChunkName: "PricingScene" */
  'scenes/General/PricingScene'
))
const RedeemGiftCertificate = lazy(() => import(
  /* webpackChunkName: "RedeemGiftCertificate" */
  'scenes/General/RedeemGiftCertificate'
))


//
// -- Artist -- //
//

const Community = lazy(() => import(
  /* webpackChunkName: "Community" */
  'scenes/Artist/Community'
))


// Story
const StoriesHome = lazy(() => import(
  /* webpackChunkName: "StoriesHome" */
  'scenes/Stories/Home'
))


//
// -- Community -- //
//

const CommunityDownloads = lazy(() => import(
  /* webpackChunkName: "CommunityDownloads" */
  'scenes/Community/Downloads'
))


// Child Route Scenes
import accounts from './accounts'
import projects from './projects'
import stories from './stories'
import artists from './artists'
import favorites from './favorites'


// Routes
const AppRoutes = {
  'accounts': {
    path: '/accounts',
    container: Authentication,
    routes: { ...accounts }
  },
  'artistApplication': {
    path: '/artist-application',
    container: App,
    loginRequired: false,
    component: ArtistApplication
  },
  'artists': {
    path: '/artists',
    container: App,
    loginRequired: false,
    component: Community,
    routes: { ...artists }
  },
  'auth-signup': {
    path: '/signup',
    container: Authentication,
    loginRequired: false,
    component: SignupScene
  },
  'auth-signin': {
    path: '/signin',
    container: Authentication,
    loginRequired: false,
    component: SignInScene
  },
  'auth-new-account': {
    path: '/new-account',
    container: Authentication,
    loginRequired: false,
    component: EnterPasswordScene
  },
  'authenticated_landing': {
    path: '/browse',
    container: App,
    loginRequired: false,
    component: AuthenticatedLanding
  },
  'batches': {
    path: '/small-batch',
    container: App,
    loginRequired: false,
    component: BatchesLanding
  },
  'business': {
    path: '/professional',
    container: App,
    loginRequired: false,
    component: BusinessLanding
  },
  'cards': {
    path: '/cards/:slug_name',
    container: App,
    loginRequired: false,
    component: CardData
  },
  'cardShop': {
    path: '/card-shop/:slug_name',
    container: App,
    loginRequired: false,
    component: CardShop
  },
  'contacts': {
    path: '/address-book',
    container: App,
    component: AddressBook,
    routes: {
      import: {
        path: '/import',
        component: ImportContacts
      }
    }
  },
  'contactUs': {
    path: '/contact-us',
    container: App,
    component: ContactUs,
    loginRequired: false
  },
  'downloads': {
    path: '/downloads',
    loginRequired: false,
    container: App,
    component: CommunityDownloads
  },
  'events': {
    path: '/events/:eventId',
    container: App,
    loginRequired: false,
    component: EventDetail
  },
  'favorites': {
    path: '/favorites',
    container: App,
    loginRequired: false,
    routes: { ...favorites }
  },
  'genres': {
    path: '/genres/:slug_name',
    loginRequired: false,
    container: App,
    component: Genres
  },
  'landing': {
    path: '/(home|card-shop)',
    container: App,
    loginRequired: false,
    component: MainLanding
  },
  'holiday-cards-request': {
    path: '/holiday-cards-catalog',
    container: App,
    component: HolidayCardsRequest,
    loginRequired: false
  },
  'home': {
    path: '/',
    container: Authentication,
    loginRequired: false,
    component: MainLanding
  },
  'newsletter': {
    path: '/newsletter',
    container: Authentication,
    routes: {
      general: {
        path: '/signup',
        component: GeneralNewsletterSignup,
        loginRequired: false
      },
      corporate: {
        path: '/business',
        component: CorporateNewsletterSignupScene,
        loginRequired: false
      }
    }
  },
  'post-recipient': {
    path: '/post-recipient',
    container: App,
    routes: {
      confirm: {
        path: '/:post_id/confirm',
        component: ConfirmPostReceived,
        loginRequired: false
      },
      deny: {
        path: '/:post_id/deny',
        component: DenyPostReceived,
        loginRequired: false
      }
    }
  },
  'pricing': {
    path: '/pricing',
    container: App,
    component: PricingScene,
    loginRequired: false
  },
  'projects': {
    path: '/projects',
    container: App,
    component: ProjectsListScene,
    routes: { ...projects }
  },
  'outbox': {
    path: '/outbox',
    container: App,
    component: Outbox,
    routes: {
      sentDetail: {
        path: '/:post_id',
        component: SentDetail,
        loginRequired: false
      }
    }
  },
  'redeem': {
    path: '/redeem',
    container: App,
    component: RedeemGiftCertificate,
    loginRequired: false
  },
  'request-address': {
    path: '/contacts/:token/address-setup',
    loginRequired: false,
    container: Authentication,
    component: RequestAddress
  },
  'stories': {
    path: '/stories',
    container: App,
    loginRequired: false,
    component: StoriesHome,
    routes: { ...stories }
  },
  'weddings': {
    path: '/wedding',
    container: App,
    loginRequired: false,
    component: WeddingsLanding
  },
  'notFound': {
    path: '*',
    container: App,
    component: NotFound
  }
}

/**
  * Returns computed given a route.name
  * @param {string} name: name of the route. e.g: 'root:login'
  * @param {object} params: all params that will be replaced if route.name was found
  *   e.g:
  *     route.name: 'accounts:profile' => '/accounts/profile/:user_id'
  *     params: { user_id: 4 }
  *     returns: '/accounts/profile/4/'
  * @returns {uri}
**/
export const reverse = (name, params = {}) => {
  const chunks = name.split(':')
  let chunkIdx = 0
  let chunk = chunks[chunkIdx]
  let route = AppRoutes[chunk]
  let uri = route.path

  // if is nested route (parent:child:grandchild)
  // compute nested path => 'parent/child/grandchild/'
  for (++chunkIdx; chunkIdx < chunks.length; chunkIdx = chunkIdx + 1) { // eslint-disable-line
    // eslint-disable-line
    // eslint-disable-line
    chunk = chunks[chunkIdx]
    route = route.routes[chunk]
    if (typeof route === 'undefined') {
      // no route found for given name
      // i.e parent.routes.child is undefined
      throw new Error(`Invalid sub-route: ${chunk}`)
    }

    // concatenated nested routes => route.routes
    uri = `${uri}${route.path}`
  }

  // replace params with passed params values
  // => /parent/child/:child_id => /parent/child/3
  return uri.replace(/:([a-zA-Z_]+)/g, (match, key) => params[key] || key)
}

const DefaultContainer = props => <div> {props.children} </div>


/*
 * Render function to be passed down to react-router's <Route render={} />
 */
const _routeRenderer = (Container = DefaultContainer, component = null) => {
  return props => (
    <Container>
      {!!component && React.createElement(component, props)}
    </Container>
  )
}


/*
 * Create react-router's <Route />-s elements
 * if given route has `{ container: ReactComponent }` defined,
 * `{ container: ReactComponent }` will be wrapped into `container`
 *
 * This function is designed to be called by lodash's `map` function
 *
 * @param {Route Object} route: group of routes to be created
 * @param {string} name: key of the Route Object in Routes being mapped (e.g accounts: {})
 * @param {object} collection: third param provided by lodash map function
 * @param {string} prevPath: parent path, used for nested routes => `/accounts/profile/:user_id/`
 * @param {React Component} parentContainer: all nested routes will inherit from parent's Container
 *                                           unless a nested route has its own Container specified.
 * By default all routes will be created with `LoginRequiredRoute` element
 * and `exact=true`
*/
const createRoutes = (
  route,
  name,
  collection,
  prevPath = '',
  parentContainer = DefaultContainer
) => {
  const {
    routes = {},
    exact = true,
    loginRequired = true,
    component: Component,
    container: Container = parentContainer,
    ...props
  } = route

  const path = `${prevPath}${route.path}`
  const Route = loginRequired ? LoginRequiredRoute : ReactRoute

  const _routes = [
    <Route
      {...props}
      path={path}
      key={path}
      render={_routeRenderer(Container, Component)}
      exact={exact}
    />
  ]

  // function to send current path (path:string) and Container (Container:ReactComponent)
  // to recursive function createRoutes in order to render sub-routes components
  const createSubRoutes = (_route, _name, _collection) =>
    createRoutes(_route, _name, _collection, path, Container)

  // if current route-group has sub-routes create them and add them to routes-bulk
  // return Route otherwise.
  return Object.keys(routes).length
    ? _routes.concat(_map(routes, createSubRoutes))
    : _routes
}


/**
  * <Routes /> component to be included under react-router-redux's ConnectedRouter
  * @returns { Switch } from 'react-router-dom'
**/
export default function Routes() {
  return (
    <Suspense fallback={<RouterLoader />}>
      <Switch>{_map(AppRoutes, createRoutes)}</Switch>
    </Suspense>
  )
}
