import { fromJS } from 'immutable'

import {
  SET_RECIPIENT,
  SET_DEFAULT_CARD,
  SET_EDIT_SENDER,
  SET_EVENT,
  SET_SENDER_CONTACT,
  SET_NEXT_BACK_VIEWS,
  SET_CONTACT_ID,
  RESET_STORE,
  UPDATE_EXTRA_PHOTOS,
  UPDATE_PRICES,
  UPDATE_POST,
  UPDATE_GIFT
} from './constants'

export const initialState = fromJS({
  prices: {},
  contactId: null,
  views: {
    current: 'messageComposer',
    next: 'extrasPicker',
    back: null
  },
  paymentCard: null,
  form: {
    messageCounter: {
      chars: 0,
      emojis: 0
    },
    price: {
      emojiMax: 4,
      extraCharacterLimit: 270,
      maxChars: 400,
      price: 7.75
    }
  },
  gift_certificate: {
    amount: null,
    message: '',
    isActive: false
  },
  post: {
    accent_colors: [],
    audience_type: null,
    more_style_options: [],
    backColor: null,
    card: {
      backColor: [0, 0, 0],
      cardThumbnail: '',
      price: {
        extraCharacterLimit: 270,
        extraCharacterPrice: 0,
        maxChars: 400,
        price: 7.75
      }
    },
    Card: '',
    cardId: null,
    cardsCollectionId: null,
    cardStatus: 'new',
    comment: null,
    deliverDate: null,
    deliveryRule: 'send_now',
    discount: 0,
    event: null,
    extraCharacters: false,
    gift_certificate_id: null,
    glitter: false,
    handDelivery: false,
    style: 'Fun',
    id: null,
    is_international: false,
    is_active: true,
    Message: '',
    occasion: null,
    photoCount: 0,
    photoPrint: false,
    photos: [],
    platformSource: 'web',
    prepDate: null,
    dueDate: null,
    recipient: null,
    receivedConfirmationShouldRequest: false,
    Sender: null,
    senderContact: null,
    senderContactId: null,
    shouldIncludeSpecificDoodle: false,
    specificDoodle: '',
    Status: false,
    subtotalPay: 0,
    totalPay: 0
  },
  isSender: false
})

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_STORE:
      return initialState
    case SET_CONTACT_ID:
      return state.set('contactId', action.id)
    case SET_DEFAULT_CARD:
      return state.set('paymentCard', action.card)
    case SET_EDIT_SENDER:
      return state.set('isSender', !state.toJS().isSender)
    case SET_EVENT:
      return state.set('event', action.event)
    case SET_NEXT_BACK_VIEWS:
      return state.merge(fromJS({
        views: {
          current: action && action.current,
          next: action.next,
          back: action.back
        }
      }))
    case SET_RECIPIENT:
      return state.merge(fromJS({
        post: {
          ...state.toJS().post,
          recipient: action.recipient,
          receivedConfirmationShouldRequest: action.recipient.email ? true : false
        }
      }))
    case SET_SENDER_CONTACT:
      if (typeof action.contact === 'object') {
        return state.merge(fromJS({
          post: {
            ...state.toJS().post,
            Sender: action.contact.contactName,
            senderContactId: action.contact.id
          }
        }))
      }
      return state.merge(fromJS({
        post: {
          ...state.toJS().post,
          sender: action.contact.contactName,
          senderContact: action.contact,
          senderContactId: action.contact.id
        }
      }))
    case UPDATE_GIFT:
      return state.merge(fromJS({
        gift_certificate: {
          ...state.toJS().gift_certificate,
          ...action.gift
        }
      }))
    case UPDATE_POST:
      return state.merge(fromJS({
        post: {
          ...state.toJS().post,
          ...action.post
        }
      }))
    case UPDATE_PRICES:
      return state.set('prices', fromJS({ ...action.prices }))
    case UPDATE_EXTRA_PHOTOS:
      return state.merge(fromJS({
        post: {
          ...state.toJS().post,
          photos: action.photos,
          photoCount: action.photos.length,
          photoPrint: action.photos.length > 0 ? true : false
        }
      }))
    default:
      return state
  }
}
