//
// -- TieredPricing Component -- //
//
// Created by Santiago Prieto on 11/11/2019
// Copyright 2023 Punkpost Inc.
//


// Base
import React from 'react'
import BaseComponent from 'utils/BaseComponent'
import { withRouter } from 'react-router-dom'
import { reverse } from 'routes'
import './styles.less'

// Local constants
import { CONSTANTS } from 'constants/TieredPricing'


// Base Component
class TieredPricingComponent extends BaseComponent {
  constructor() {
    super()
    this._bind(
      '_goToCollection'
    )
  }


  // Handler

  _goToCollection(tier) {
    reverse('cardShop', { slug_name: tier.collection })
  }

  _goToCollection(e, tier) {
    const url = reverse('cardShop', { slug_name: tier.collection })
    if (e.metaKey) {
      window.open(url, '_blank')
    } else {
      this.props.history.push(url)
    }
  }


  // RENDER

  _renderHeaderSection() {
    return (
      <div className="TieredPricingComponent__HeaderSection">
        <h4>
          {this.props.constants && this.props.constants.general.header || CONSTANTS.general.header}
        </h4>
        <div className="TieredPricingComponent__HeaderSection__Details">
          {CONSTANTS.general.details.map((element, index) => {
            return (
              <p key={index}>
                {`✔︎  ${element}`}
              </p>
            )
          })}
        </div>
      </div>
    )
  }

  _renderBullet(text) {
    return (
      <p>{text}</p>
    )
  }

  _renderTier(tier) {
    return (
      <div>
        <div className="TieredPricingComponent__Container__Tier__Preferred">
          {tier.name === CONSTANTS.tier2.name && (
            <picture>
              <img src={CONSTANTS.preferred_img} alt="most popular"/>
            </picture>
          )}
        </div>
        <div
          className="TieredPricingComponent__Container__Tier"
          style={{ borderColor: tier.color }}
        >
          <div className="TieredPricingComponent__Container__Tier__Badge">
            <picture>
              <img src={tier.badge_img} alt={tier.name}/>
            </picture>
          </div>
          <div className="TieredPricingComponent__Container__Tier__Content">
            <div className="TieredPricingComponent__Container__Tier__Content__Card">
              <picture>
                <img src={tier.card_img} alt={tier.name}/>
              </picture>
              <h4 style={{ color: tier.color }}>{tier.price}</h4>
              <p>per card</p>
            </div>
            <div className="TieredPricingComponent__Container__Tier__Content__Details">
              {tier.details.map((element, index) => {
                return (
                  <p key={index}>
                    {`∙ ${element}`}
                  </p>
                )
              })}
            </div>
            {this.props.showShopButton && (
              <button value={tier} onClick={(e) => this._goToCollection(e, tier)}>
                shop
              </button>
            )}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="TieredPricingComponent">
        <h3>
          {this.props.constants && this.props.constants.header || CONSTANTS.header}
        </h3>
        <p>{CONSTANTS.body}</p>
        <div className="TieredPricingComponent__Container">
          {this._renderTier(this.props.constants && this.props.constants.tier1 || CONSTANTS.tier1)}
          {this._renderTier(this.props.constants && this.props.constants.tier2 || CONSTANTS.tier2)}
          {this.props.showTier3 && this._renderTier(
            this.props.constants && this.props.constants.tier3 || CONSTANTS.tier3
          )}
        </div>
        {
          // this._renderHeaderSection()
        }
      </div>
    )
  }
}

export default withRouter(TieredPricingComponent)


//
// -- Defaults -- //
//

TieredPricingComponent.defaultProps = {
  showShopButton: true,
  showTier3: true
}
