//
// -- VERIFY Events & Holidays -- //
//
// Created by Santiago Prieto on July 15, 2022.
// Copyright 2023 Punkpost Inc.
//


// Base
import Parse from 'parse'

// Constants
const Event = Parse.Object.extend('Event')
const Contact = Parse.Object.extend('Contacts')
const Holiday = Parse.Object.extend('Holiday')


/**
  * @function verifyEventExists
  * @param {boolean} holiday for event
  * @param {boolean} contact for event
  * @returns {Promise} query
**/
export const verifyEventExists = (holiday, contact) => {
  const currentUser = Parse.User.current()
  const referenceHoliday = new Holiday()
    .set('objectId', holiday.id)
  const referenceContact = new Contact()
    .set('objectId', contact.id)

  const query = new Parse.Query(Event)
    .equalTo('user', currentUser)
    .equalTo('holiday', referenceHoliday)
    .equalTo('contact', referenceContact)
    .notEqualTo('is_active', false)
  return query.first()
}
