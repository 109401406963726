//
// -- Footer Atom -- //
//
// Created by Santiago Prieto on 2018.
// Copyright 2023 Punkpost Inc.
//


// Base
import React from 'react'
import BaseComponent from 'utils/BaseComponent'
import { reverse } from 'routes'
import { withRouter } from 'react-router-dom'
import './styles.less'

// Constants
// import {
  // PRODUCT_LINKS,
  // COMPANY_LINKS,
  // LEGAL_LINKS,
  // SOCIAL_LINKS
// } from 'constants'

// const PUNKPOST = `Punkpost`
// const COMPANY = `Company`
// const SUPPORT = `Support`
// const HELP = `Help and FAQs`
// const CONTACT = `Contact Us`
// const LEGAL = `Legal`
// const SOCIAL = `Social`
const COPYRIGHT = `Copyright © 2024 Punkpost Inc. ® All rights reserved.`


// -- Atom -- //
class PageFooter extends BaseComponent {
  constructor() {
    super()
    this._bind(
      '_handleContactUs'
    )
  }


  //
  // -- Handers -- //
  //

  _handleContactUs(e) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    const route = reverse('contactUs')
    if (e.metaKey) {
      window.open(route, '_blank')
    } else {
      this.props.history.push(route)
    }
  }


  //
  // -- Render -- //
  //

  _renderLink(link, index) {
    return (
      <li key={link.name + index}>
        <a href={link.url} target="_blank" rel="noopener">
          {link.name}
        </a>
      </li>
    )
  }

  render() {
    // const products = PRODUCT_LINKS ? PRODUCT_LINKS : []
    // const company = COMPANY_LINKS ? COMPANY_LINKS : []
    // const legal = LEGAL_LINKS ? LEGAL_LINKS : []
    // const social = SOCIAL_LINKS ? SOCIAL_LINKS : []
    return (
      <div className="LandingFooter">
        <div className="LandingFooter__Container">
        {
          // <div className="LandingFooter__Container__Columns">
          //   <div className="LandingFooter__Container__Columns__Column">
          //     <p className="LandingFooter__Container__Columns__Column__Title">
          //       {PUNKPOST}
          //     </p>
          //     <ul>{products.map(this._renderLink)}</ul>
          //   </div>
            // <div className="LandingFooter__Container__Columns__Column">
            //   <p className="LandingFooter__Container__Columns__Column__Title">
            //     {COMPANY}
            //   </p>
            //   <ul>{company.map(this._renderLink)}</ul>
            // </div>
            // <div className="LandingFooter__Container__Columns__Column">
            //   <p className="LandingFooter__Container__Columns__Column__Title">
            //     {SUPPORT}
            //   </p>
            //   <ul>
            //     <li>
            //       <a href={'https://punkpost.co/help'} target="_blank" rel="noopener">
            //         {HELP}
            //       </a>
            //     </li>
            //     <li>
            // <button onClick={(e) => this._handleContactUs(e)} target="_blank" rel="noopener">
            //         {CONTACT}
            //       </button>
            //     </li>
            //   </ul>
            //   <p className="LandingFooter__Container__Columns__Column__Title">
            //     {LEGAL}
            //   </p>
            //   <ul>{legal.map(this._renderLink)}</ul>
            // </div>
          //   <div className="LandingFooter__Container__Columns__Column">
          //     <p className="LandingFooter__Container__Columns__Column__Title">
          //       {SOCIAL}
          //     </p>
          //     <ul>{social.map(this._renderLink)}</ul>
          //   </div>
          // </div>
          }
          <p className="LandingFooter__Container__Copy">
            {COPYRIGHT}
          </p>
        </div>
      </div>
    )
  }
}

export default withRouter(PageFooter)
