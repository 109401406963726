// -- CreditCardForm Reducer -- //

// Base
import { fromJS } from 'immutable'

// Constants
import { SET_PROJECT } from './constants'


export const initialState = fromJS({
  project: null
})


export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROJECT:
      return state.set('project', action.projectId)

    default: return state
  }
}
