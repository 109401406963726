// -- AddressBook Reducer -- //

// Base
import { fromJS, List } from 'immutable'

// Constants
import {
  SEARCH_BY_PARAMS,
  CONTACTS_FETCHED,
  SELECT_ALL_CHANGE,
  TOGGLE_CONTACT_DELETION,
  RESET_SELECT_ALL,
  SHOW_CONTACTS_ERROR
} from './constants'


// Initial State
export const initialState = fromJS({
  search: '',
  field: 'fullName',
  contacts: {},
  contactsLength: null,
  isLoading: true,
  selectAllStatus: false,
  contactsToDelete: [],
  error: null
})


// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_SELECT_ALL:
      return state.merge(fromJS({
        contactsToDelete: List([]),
        selectAllStatus: false
      }))

    case SEARCH_BY_PARAMS:
      return state.merge(fromJS({
        search: action.search,
        field: action.field,
        isLoading: action.search ? true : false
      }))

    case CONTACTS_FETCHED:
      return state.merge(fromJS({
        contacts: action.contacts,
        contactsLength: action.contactsLength,
        isLoading: false
      }))

    case SELECT_ALL_CHANGE:
      return state.merge(fromJS({
        selectAllStatus: action.status,
        contactsToDelete: action.status
          ? state.toJS().contactsToDelete
          : List([])
      }))

    case SHOW_CONTACTS_ERROR:
      return state.merge(fromJS({
        isLoading: false,
        error: state.toJS().error
      }))

    case TOGGLE_CONTACT_DELETION:
      // if exists then remove it from deleted items
      const indexOf = state.get('contactsToDelete').indexOf(action.id)
      if (indexOf >= 0) {
        return state.set(
          'contactsToDelete',
          state.get('contactsToDelete').remove(indexOf)
        )
      }
      return state.set(
        'contactsToDelete',
        state.get('contactsToDelete').push(action.id)
      )

    default:
      return state
  }
}
