/*
  All of the project will be rendered by this file and will be
  rendered in the <div id="Punkpost"> in the Body of public/index.html
*/

// Base
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { Helmet } from 'react-helmet'
import { Provider, connect } from 'react-redux'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware } from 'redux'
import createReducer from 'reducers'
import thunk from 'redux-thunk'
import 'styles/app.less'

// Utils
import PinterestTag from 'utils/analytics/pinterest'
import createRoutes from 'routes'
import { initialize as initializeParse } from 'utils/parse'
import { isAuthenticated } from 'utils/auth'

// Actions
import { refreshProfile } from 'reducers/account/actions'
import { refreshFavoriteCards } from 'reducers/favoriteCards/actions'

// Services
import { refreshConfig } from 'services/config'

// Components
import CacheBuster from 'cacheBuster'
import Notification from 'components/Notification'
import registerServiceWorker from './registerServiceWorker'

// Constants
import { HELMET } from 'constants'
const APP_VERSION = '1.4.305'

initializeParse()

export const history = createBrowserHistory()

if (process.env.NODE_ENV !== 'test') {
  Sentry.init({
    dsn: 'https://86119e7539dd4b68ac12c1e3e4501190@o154331.ingest.sentry.io/1208738',
    release: `web-app@${APP_VERSION}`,
    integrations: [new BrowserTracing()],
    // 1.0 means 100% of transactions will be recoreded.
    // Lower the number to 0.30 or so if quota gets too large.
    tracesSampleRate: 0.30
  })
  PinterestTag.init(process.env.PINTEREST_TAG)
}

const store = createStore(
  createReducer(history),
  applyMiddleware(thunk),
  applyMiddleware(routerMiddleware(history))
)


export default class App extends Component {
  componentDidMount() {
    // Call global actions to avoid calling them on each Container-App mount.
    refreshConfig()
    if (isAuthenticated()) {
      this.props.fetchFavoriteCards()
      this.props.fetchProfile()
    }
  }

  _renderHelmet() {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{HELMET.appName}</title>
        <meta name="description" content={HELMET.appDescription}/>
        <meta property="og:title" content={HELMET.appName}/>
        <meta property="og:description" content={HELMET.appDescription}/>
        <meta property="og:site_name" content={HELMET.appName}/>
        <meta property="og:image" content={HELMET.appCoverImage} />
        <meta property="og:url" content={HELMET.appURL} />
        <meta property="og:type" content="website" />
        <meta property="fb:app_id" content={process.env.REACT_APP_PARSE_FACEBOOK_ID}/>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={HELMET.appTwitter} />
      </Helmet>
    )
  }

  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) {
            return null
          }
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload()
          }
          return (
            <Provider store={store}>
              <ConnectedRouter history={history}>
                <div className="Scene__container">
                  <Notification />
                  {this._renderHelmet()}
                  {createRoutes()}
                </div>
              </ConnectedRouter>
            </Provider>
          )
        }}
      </CacheBuster>
    )
  }
}


//
// -- Reducers -- //
//

const mapDispatchToProps = dispatch => {
  return {
    fetchFavoriteCards: () => dispatch(refreshFavoriteCards()),
    fetchProfile: () => dispatch(refreshProfile())
  }
}

// The main `store` component of the application:
const ConnectedApp = connect(null, mapDispatchToProps)(App)

if (process.env.NODE_ENV !== 'test') {
  // Pass down same `store` that is used in <Provider store />
  ReactDOM.render(
    <ConnectedApp store={store} />,
    document.getElementById('Punkpost')
  )
  registerServiceWorker()
}
