// -- Cart Reducer -- //

// Base
import { fromJS } from 'immutable'

// Constants
import {
  ADD_POST, REMOVE_POST,
  ADD_GIFT_CERTIFICATES, REMOVE_GIFT_CERTIFICATE,
  UPDATE_PRICES, UPDATE_GIFT_CERTIFICATES
} from './constants'

// Utils
import { computeSummary, defaultSummary } from 'utils/prices'


export const initialState = fromJS({
  posts: [],
  gift_certificates: {},
  prices: {
    photoSquare: 1.5,
    glitter: 1.5,
    international: 1,
    handDelivery: 1
  },
  summary: { ...defaultSummary }
})


export default function reducer(state = initialState, action) {
  let posts = state.get('posts')
  const prices = state.get('prices').toJS()
  let giftCertificates = state.get('gift_certificates')

  switch (action.type) {
    case ADD_POST:
      posts = posts.push(fromJS(action.post))
      return state
        .set('posts', posts)
        .set('summary', computeSummary(posts, prices, giftCertificates))

    case REMOVE_POST:
      const index = posts.toJS().findIndex(post => {
        return post.id === action.post
      })
      if (index >= 0) {
        return state.set('posts', posts.splice(index, 1))
      }
      return state.set('posts', posts)

    case ADD_GIFT_CERTIFICATES:
      giftCertificates = fromJS(action.giftCertificates)
      return state
        .set('gift_certificates', giftCertificates)
        .set('summary', computeSummary(posts, prices, action.giftCertificates))

    case REMOVE_GIFT_CERTIFICATE:
      giftCertificates = giftCertificates.delete(action.giftCertificates)
      return state.set('gift_certificates', giftCertificates)

    case UPDATE_PRICES:
      return state.set('prices', fromJS(action.prices))

    case UPDATE_GIFT_CERTIFICATES:
      return state
        .set('gift_certificates', fromJS(action.gift_certificates))
        .set('summary', computeSummary(posts, prices, action.gift_certificates))

    default:
      return state
  }
}
