//
// -- Tiered Pricing Constants -- //
//
// Created by Satiago Prieto on 11/11/2019
// Copyright 2023 Punkpost Inc.
//


// Dictionary
export const CONSTANTS = {
  header: `Fun cards for every occasion.`,
  body: `Punkpost is the perfect way to celebrate any occasion. whether you're
  sending a birthday card, thank you card, or just because card, our team of
  artists will handwrite your message and mail it for you.`,
  preferred_img: require('img/promotion/punkpost-most-popular.webp'),
  general: {
    header: `All cards include...`,
    details: [
      `Your message handwritten by an artist.`,
      `The greeting card of your choice.`,
      `An envelope with handwritten address.`,
      `USPS forever stamp and shipping.`,
      `Photos of the handwriting in your outbox.`
    ]
  },
  tier1: {
    name: 'note card',
    badge_img: require('img/general/card-badge-note.webp'),
    card_img: require('img/general/card-template-note.webp'),
    color: '#000000',
    collection: 'note-cards',
    price: '$6.00',
    details: [
      'Flat card',
      '4.25 x 5.5 (A2)',
      '150 characters max',
      '2 emojis typed max'
    ]
  },
  tier2: {
    name: 'standard',
    badge_img: require('img/general/card-badge-standard.webp'),
    card_img: require('img/general/card-template-standard.webp'),
    color: '#BD10E0',
    collection: 'standard-cards',
    price: '$8.75',
    details: [
      'Regular folded card',
      '4.25 x 5.5 (A2 folded)',
      '270 characters max',
      '4 emojis typed max'
    ]
  },
  tier3: {
    name: 'premium',
    badge_img: require('img/general/card-badge-premium.webp'),
    card_img: require('img/general/card-template-premium.webp'),
    color: '#A17C4F',
    collection: 'premium-cards',
    price: '$11.99',
    details: [
      'Large folded card',
      'With foil printing',
      '5 1/8 x 7 (A7 folded)',
      '300 characters max',
      '5 emojis typed max'
    ]
  }
}
