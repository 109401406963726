import 'whatwg-fetch'

import { getCurrentUserToken, logout } from './auth'


/**
  * Parse to JSON function.
  * @param {response} response is content of request response.
  * @return {json} a JSON response.
*/
async function parseJSON(response) {
  return response.json()
}


/**
  * Get text content of element.
  * @param {response} response is content of request response.
  * @return {text} a plain text.
*/
async function parseTxt(response) {
  return response.text()
}


/**
  * Catch network request error or any other unexpected error.
  * @param {e} e is any error from request.
  * @return {error} Return object with status and message.
*/
function catchNetworkErrors(e) {
  const error = new Error(e)
  error.response = {
    status: 'error',
    message: 'No Internet Connection'
  }
  throw error
}


/**
  * Catch invalid api response.
  * @param {e} e is any error from request.
  * @return {e} e is an error with status and messaje on [response] key.
*/
function catchInvalidAPIResponse(e) {
  if (e.name === 'FetchError') {
    e.response = {
      status: 'error',
      message: e.message
    }
    throw e
  }
  throw e
}


/**
  * Checks if a network request came back fine, and throws an error if not.
  * @param {response} response is any response from request.
  * @return {json} JSON if 'Content-Type' exist'.
  * @return {text} plain text if 'Content-Type' no exist.
  * @return {logout} logut current sesion for invalid token.
  * @return {error} any error if there is one.
*/
async function checkStatus(response) {
  // response.ok is true if 200 < response.status < 299
  // +info: https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
  if (response.ok) {
    const parser = response.headers.get('Content-Type') ? parseJSON : parseTxt
    return parser(response)
  } else if (response.status === 401) {
    return logout('Invalid token.')
  } else if (response.status === 500) {
    const error = new Error(response.statusText ? response.statusText : null)
    error.response = 'Internal error. Please contact admin.'
    return error
  }
  // if request was made with valid params (i.e method is allowed and URI is correct)
  // parse response as JSON to avoid parsing on each .catch() and duplicating code.
  return parseJSON(response)
    .then(json => {
      if (response && json) {
        const error = new Error(response.statusText ? response.statusText : null)
        error.response = json
        throw error
      }
    })
}


/**
  * Request
  * @param {url} url of request (string).
  * @param {method} method of request ['POST', 'PUT', 'DELETE', ...] (string).
  * @param {body} body of request (object.
  * @param {headers} headers of request (object).
  * @param {isUpload} content is a file (boolean).
  * @param {isParse} request is related to parse (boolean).
  * @return {promise} return a Promise
*/
export default function request(
  url,
  {
    method = 'GET',
    body = {},
    headers = {},
    isUpload = false,
    isParse = true
  } = {}
) {
  let parseToken = null

  const _request = {
    method: method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      ...headers
    },
    body: !isUpload ? JSON.stringify(body) : body
  }

  if (isParse) {
    parseToken = getCurrentUserToken()
    _request.headers = {
      ..._request.headers,
      Authorization: `X-Parse-Token ${parseToken}`
    }
  }

  if (isUpload) {
    delete _request.headers['Content-Type']
  }
  if (method === 'GET') {
    delete _request.body
  }

  if (process.env.NODE_ENV === 'test') {
    _request.headers['Access-Control-Allow-Origin'] = '*'
  }

  return fetch(url, _request)
    .catch(catchNetworkErrors)
    .then(checkStatus)
    .catch(catchInvalidAPIResponse)
}
