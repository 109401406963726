
export const HELMET = {
  siteName: 'Punkpost',
  appName: 'Punkpost Greeting Cards',
  appDescription: 'Beautifully Handwritten by Artists for You.',
  appTwitter: '@PunkpostCo',
  appURL: 'https://punkpost.com',
  appCoverImage: 'https://hello.punkpost.rocks/parse/files/YFErfCNJHBex0V2QtDfeyhMsIwXoxV5rF6an' +
    'bW9k/bbb1242650ad00785ab905a967d5bfcd_punkpost-your-butt-is-perfect-2.jpg'
}
