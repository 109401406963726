

export const LANDING = {
  logo: require('img/general/punkpost-logo.png'),
  logoWebp: require('img/general/punkpost-logo.webp'),
  tagline: 'Beautifully Handwritten Cards Mailed by Artists for You',
  header: 'Your message, your creativity, your card. Our team handles the rest. Cards are mailed within 1 business day.', // eslint-disable-line
  subheader: 'Over 450,000 custom cards sent.',
  heroImage: require('img/backgrounds/punkpost-envelopes-01.jpg'),
  heroImageWebp: require('img/backgrounds/punkpost-envelopes-01.webp')
}
