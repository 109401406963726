// -- Update CLIENT NOTIFICATIONS -- //

// Base
import Parse from 'parse'

// Reference Objects
const ClientNotification = Parse.Object.extend('ClientNotification')


/**
  * @function updateClientNotificationAsRead
  * @param {string} notification_id of notification
  * @returns {Promise} notification data
**/
export const updateClientNotificationAsRead = (notification_id) => {
  if (!notification_id) {
    return null
  }
  const notification = new ClientNotification()
  notification.set('objectId', notification_id)
  notification.set('is_read', true)
  return notification.save()
}


/**
  * @function updateClientNotificationsAsRead
  * @param {array} notifications of notifications
  * @returns {Promise} notification data
**/
export const updateClientNotificationsAsRead = (notifications) => {
  if (!notifications) {
    return null
  }
  notifications.forEach(notification => {
    notification.set('is_read', true)
  })
  return Parse.Object.saveAll(notifications)
}
