// -- COLLECTIONS -- //

// Base
import Parse from 'parse'


export const handleParseError = error => {
  if (!error) {
    return
  }
  switch (error.code) {
    case Parse.Error.INVALID_SESSION_TOKEN:
      Parse.User.logOut()
      break

    default:
      console.log(error ? error : null)
      break
  }
}
