//
// -- Reviews (from client comments) -- //
//


export const REVIEWS = [
  {
    name: `Iris E.`,
    message: `This was the first time sending a card through this app and the handwriting
    blew me away! So cute!`,
    rating: 5
  },
  {
    name: `Heather M.`,
    message: `I literally tear up every time I order a card from you guys! You translate so well
    with your beautiful designs every single time. Thank you so much!!`,
    rating: 5
  },
  {
    name: `Britney S.`,
    message: `Absolutely perfect! Everything that I wanted but done so much better than I could
    ever imagined. Thank you!`,
    rating: 5
  },
  {
    name: `Cassandra L.`,
    message: `You nailed it! The card looks great and I know he is going to be thrilled. Thank
    you so much for using your time and talent and helping me make this day special.`,
    rating: 5
  },
  {
    name: `Rebecca W.`,
    message: `OMG this is perfect! I love how you incorporated her favorite colors for her name,
    and my favorite colors for mine. You are a rock star!`,
    rating: 5
  },
  {
    name: `Melissa P.`,
    message: `YOU ARE AMAZING! I can't get over how gorgeous this is WOWSER!!!!`,
    rating: 5
  },
  {
    name: `Alex R.`,
    message: `If I could give this card 10 stars, I would! Thank you so VERY much. Love it,
    love it!!`,
    rating: 5
  },
  {
    name: `Sam K.`,
    message: `I love your doodles and your lettering is impeccable! My best friend got the card
    today and is putting it in the baby's book. Thank you for creating this masterpiece.`,
    rating: 5
  },
  {
    name: `Colleen T.`,
    message: `Amazing style. Looks fabulous! Can't wait until she gets it. Thanks so much!`,
    rating: 5
  },
  {
    name: `Lara Joe W.`,
    message: `This truly made someone so happy and I can’t thank you enough for putting so much
    love and creativity into it!! `,
    rating: 5
  },
  {
    name: `Amanda L.`,
    message: `Ohhh my gosh!!! Absolutely loved every part of your beautiful, stunning card!
    My niece will treasure this forever :)`,
    rating: 5
  },
  {
    name: `Alaina M.`,
    message: `I have got multiple cards from this artist! Each one has been amazing and this one
    is no exception! Went above and beyond. My fiancée loved it for V-day! Thank you.`,
    rating: 5
  },
  {
    name: `Kylee W.`,
    message: `Thank you thank you! Creative and sweet, loved the chosen colors and different
    fonts used together. 🥰`,
    rating: 5
  },
  {
    name: `Lori R.`,
    message: `I absolutely adore this card!! I am going to frame it after he opens it!!
     That’s artwork love!!!`,
    rating: 5
  },
  {
    name: `Courtney M.`,
    message: `Thank you so much! You did a great job! I am so excited for my friend to
    receive her card! Beautiful ❤️`,
    rating: 5
  },
  {
    name: `Carlyn H.`,
    message: `This is so awesome!! Super fast turn around too, love that. You created such
     a beautiful card, thank you so much Michelle. 😊`,
    rating: 5
  },
  {
    name: `Mary G.`,
    message: `Absolutely amazing! I love the writing, the name for recipient looks so cool!
    The little drawings at the bottom are out of this world! Thanks so much!`,
    rating: 5
  },
  {
    name: `Joel S.`,
    message: `I freaking love this so much, and I’m sure my mom will love it even more! Thank
    you so much for your creativity, speed, and artistic talent!`,
    rating: 5
  },
  {
    name: `Lindley S.`,
    message: `Sorry I am late to this; but I can’t tell you how obsessed I am & was with your
    design. You knocked it out of the park. Thank you so so much!`,
    rating: 5
  },
  {
    name: `Lisa R.`,
    message: `Jen went above and beyond my expectations for how it would turn out! I love it!
    This is the first card I’m sending to a longtime close friend and I’m so happy with it!`,
    rating: 5
  },
  {
    name: `Joshua C.`,
    message: `You did such an outstanding job with this card and envelope!! You are extremely
    talented and I hope to have you make more cards for me in the future.`,
    rating: 5
  },
  {
    name: `Samantha K.`,
    message: `Wow, another phenomenal card. I love the emphasis you put on the words and the
    drawing of Inigo and the Dread Pirate is just incredible. Thank you so so so much.`,
    rating: 5
  },
  {
    name: `Jeanne Pauline C.`,
    message: `Amazing style - I love how simple and elegant it is but also gives an impact.
    Amazing work thank you. ☺️`,
    rating: 5
  },
  {
    name: `Nicole F.`,
    message: `WOW!! This looks amazing!! I couldn’t have asked for anything better!! It was an
    amazing surprise for me, so I can’t wait for my cousins to get it!!`,
    rating: 5
  },
  {
    name: `Alex M.`,
    message: `HOLY COW is this gorgeous — the corgi with a crown on it is adorable, and the
    overall effect is so well balanced. Thanks for making me look good with my friend!`,
    rating: 5
  },
  {
    name: `Stephanie G.`,
    message: `OMGGGGG this card…. I cannot express how much I absolutely ADORE it! I am getting
    it framed. Will be purchasing cards again soon. 1000/10 recommend!`,
    rating: 5
  },
  {
    name: `Janelle F.`,
    message: `This is the absolute most amazing card I have ever seen. Exceed my wildest
    expectations!! I cannot wait to give this card to my boyfriend!`,
    rating: 5
  },
  {
    name: `Nady P.`,
    message: `This is sheer perfection! I love the lettering so much, and the doodles
    are so sweet!`,
    rating: 5
  },
  {
    name: `Jordan P.`,
    message: `Wow this card is jaw dropping. Thank you for following instruction and making an
    excellent design.`,
    rating: 5
  },
  {
    name: `Nicholas O.`,
    message: `Thank you for this amazing card for my friend going through a difficult time.
    I love it and appreciate it!`,
    rating: 5
  },
  {
    name: `Lindsay Roberta D.`,
    message: `This is EXACTLY what grandma will love!! Neat penmanship, pretty details that
    match! Thank you!!`,
    rating: 5
  },
  {
    name: `Monica S.`,
    message: `You’ve made the start of my week so joyful! I can’t wait for my friend to receive
    this card. Thank you.`,
    rating: 5
  },
  {
    name: `Charlene H.`,
    message: `I’m obsessed with your artwork and style!! This is amazing and made my dreams
    come true. Thank you.`,
    rating: 5
  },
  {
    name: `Josh C.`,
    message: `Wow. Katy you did an outstanding job! I’m blown away by how it all looks.
    You have an amazing talent!! Thanks so much!`,
    rating: 5
  },
  {
    name: `Kattizi U.`,
    message: `I love everything about this…especially the extra illustration of the 🐙! Your
    designs, color choices, and lettering are perfectly personalized. Thank you!!`,
    rating: 5
  },
  {
    name: `Jac C.`,
    message: `It’s amazing, exactly the way I imagined it! Added extra details based on the
    extra info I included when ordering the card. Thank you!`,
    rating: 5
  },
  {
    name: `GG R.`,
    message: `My (almost) 13 year old Granddaughter will LOVE IT!!!`,
    rating: 5
  },
  {
    name: `Courtney L.`,
    message: `He came in looking at me like he couldn’t believe it. We JUST got it and
    I can’t complain.`,
    rating: 5
  },
  {
    name: `Liz E.`,
    message: `My husband absolutely LOVED his card!! He said it is his favorite from all the
    cards I’ve sent him 😉 it’s his first Punkpost. Beautifully done.`,
    rating: 5
  },
  {
    name: `Mary G.`,
    message: `I love the color scheme and how it continued from the card to the envelope. I also
    love how the most important words were emphasized. It that much more special!`,
    rating: 5
  },
  {
    name: `Gloria B.`,
    message: `So incredibly beautiful. I love sending these cards just to see what the artists
    come up with!`,
    rating: 5
  },
  {
    name: `Tim T.`,
    message: `Beyond my expectations!! Really took the notes I added and made something really
    special!`,
    rating: 5
  },
  {
    name: `Starling F.`,
    message: `So cute! I am very impressed with the attention to detail. I will definitely
    continue to use this site.`,
    rating: 5
  },
  {
    name: `Miranda B.`,
    message: `My friend cried when she saw your beautiful card creation! I'm so impressed with
    your artistic eye for color and design. Thank you!!`,
    rating: 5
  },
  {
    name: `Amanda R.`,
    message: `This is sooo cute!! Thank you so much for making the perfect card for my family.
    They will love this and have it forever.`,
    rating: 5
  },
  {
    name: `Anna B.`,
    message: `Love it! Punkpost has been so amazing the past two years! I’m overseas for school
    and the post takes FOREVER from here so I love that I can use this to send cards!`,
    rating: 5
  }
]
