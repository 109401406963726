//
// -- Artists SubRoutes -- //
//
// Created by Santiago Prieto on August 2019
// Copyright Punkpost Inc. 2019
//


// Utils
import { lazy } from 'react'


//
// -- Scenes -- //
//

const ArtistPorfile = lazy(() => import(
  /* webpackChunkName: "ArtistPorfile" */
  'scenes/Artist/Profile'
))

const ArtistTutorials = lazy(() => import(
  /* webpackChunkName: "ArtistTutorials" */
  'scenes/Artist/ArtistTutorials'
))

const DiscoverArtists = lazy(() => import(
  /* webpackChunkName: "DiscoverArtists" */
  'scenes/Artist/DiscoverArtists'
))


//
// -- Routes -- //
//

export default {
  profile: {
    path: '/:slug_name',
    component: ArtistPorfile,
    loginRequired: false
  },
  tutorials: {
    path: '/tutorials/home',
    component: ArtistTutorials
  },
  discover: {
    path: '/community/discover',
    component: DiscoverArtists,
    loginRequired: false
  }
}
