//
// -- Event Utils -- //
//
// Created by Santiago Prieto on 2023.
// Copyright 2023 Punkpost Inc.
//

import dayjs from 'dayjs'


export const formatEventDate = (event, format = 'short') => {
  const holiday = event && event.holiday &&
    event.holiday.getAttributes()
  if (holiday && holiday.type === `closed`) {
    if (format === 'short') {
      return `${dayjs().month(holiday.month - 1).format('MMM')} ${holiday.day}`
    }
    return `${dayjs().month(holiday.month - 1).format('MMMM')} ${holiday.day}`
  }
  if (format === 'short') {
    return `${dayjs().month(event.month - 1).format('MMM')} ${event.day}`
  }
  return `${dayjs().month(event.month - 1).format('MMMM')} ${event.day}`
}


export const computeEventWorkingPostStatus = _event => {
  const workingPost = _event.workingPost && _event.workingPost.getAttributes()
  const twoMonthsAgo = dayjs().add(-2, 'month')

  if (!workingPost) {
    if (_event.lastCardSent && twoMonthsAgo < _event.lastCardSent) {
      return 'sent'
    }
    return 'new'
  }
  if (workingPost.cardStatus === 'draft' || workingPost.cardStatus === 'new') {
    return 'draft'
  }
  const deliverDate = workingPost.deliverDate
  if (deliverDate === null) {
    return 'new'
  }
  if (twoMonthsAgo > deliverDate) {
    return 'new'
  }
  if (workingPost.Status === true) {
    return 'sent'
  }
  return 'paid'
}
