import { fromJS } from 'immutable'

import {
  CHANGE_FILTER, CHANGE_SEARCH, CHANGE_SORT_ORDER,
  HANDLE_FILTER_STATUS,
  START_LOADING, SELECT_ALL_CHANGE,
  TOOGLE_ADD_RECIPIENT, TOGGLE_POST_DELETION,
  UPDATE_NOTIFICATION, UPDATE_PAGINATION,
  UPDATE_POSTS, UPDATE_PROJECT_DETAIL
} from './constants'


const PAGE_SIZE = 25


export const initialState = fromJS({
  isLoading: true,
  isLoadingPage: false,
  isDescending: true,
  hasPosts: false,
  order: 'createdAt',
  pagination: {
    currentPage: 1,
    pageSize: PAGE_SIZE,
    totalItems: 0
  },
  project: {},
  recipients: {},
  search: '',
  selectedFilter: null,
  filtersCount: {
    approved: 0,
    drafts: 0,
    sent: 0
  },
  cards: [],
  posts: {},
  postsToDelete: [],
  notification: {
    title: '',
    subtitle: '',
    visible: false,
    type: null
  },
  newProjectRecipient: '',
  selectAllStatus: false,
  isAddRecipient: false,
  filterStatus: null
})


export default function reducer(state = initialState, action) {
  let pagination
  switch (action.type) {
    case HANDLE_FILTER_STATUS:
      return state.set('filterStatus', action.status)
    case TOOGLE_ADD_RECIPIENT:
      return state.set('isAddRecipient', action.status)
    case SELECT_ALL_CHANGE:
      return state.set('selectAllStatus', action.status)

    case START_LOADING:
      // reset state but keep prices
      return state.set('isLoading', true)

    case CHANGE_FILTER:
      return state.set('selectedFilter', action.filter)

    case CHANGE_SEARCH:
      return state.set('search', action.search).set('selectedFilter', null)

    case CHANGE_SORT_ORDER:
      const isDescending = !state.get('isDescending')
      return state.set('order', action.order).set('isDescending', isDescending)

    case UPDATE_PAGINATION:
      pagination = state.get('pagination')
      return state.merge(fromJS({
        selectedFilter: state.get('selectedFilter'),
        posts: {},
        pagination: {
          currentPage:
            action.pagination.currentPage || pagination.get('currentPage'),
          pageSize: action.pagination.pageSize || pagination.get('pageSize'),
          totalItems:
            action.pagination.totalItems || pagination.get('totalItems')
        },
        isLoadingPage: true
      }))

    case TOGGLE_POST_DELETION:
      // if exists then remove it from deleted items
      const indexOf = state.get('postsToDelete').indexOf(action.id)
      if (indexOf >= 0) {
        return state.set(
          'postsToDelete',
          state.get('postsToDelete').remove(indexOf)
        )
      }
      return state.set(
        'postsToDelete',
        state.get('postsToDelete').push(action.id)
      )

    case UPDATE_POSTS:
      const filtersCount = state.get('filtersCount')
      return state.merge(fromJS({
        posts: action.posts,
        filtersCount: action.filtersCount || filtersCount,
        isLoadingPage: false,
        postsToDelete: []
      }))

    case UPDATE_PROJECT_DETAIL:
      return state.merge(fromJS({
        cards: action.cards.map(card => card ? card.getAttributes() : null),
        hasPosts: !!Object.keys(action.posts).length,
        isLoading: false,
        isLoadingPage: false,
        recipients: action.recipients,
        pagination: {
          currentPage: 1,
          pageSize: PAGE_SIZE,
          totalItems: action.postsCount
        },
        posts: action.posts,
        filtersCount: action.filtersCount,
        project: action.project ? action.project.getAttributes() : null,
        notification: state.get('notification'),
        specificSent: action.sent,
        postsToDelete: []
      }))

    case UPDATE_NOTIFICATION:
      return state.set(
        'notification',
        fromJS({
          title: action.title,
          subtitle: action.subtitle,
          visible: action.visible,
          type: action.notificationType
        })
      )

    default:
      return state
  }
}
