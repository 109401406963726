//
// -- CREATE Event -- //
//
// Created by Jonatan Santa Cruz on 2017.
// Copyright 2023 Punkpost Inc.
//


// Base
import Parse from 'parse'
import dayjs from 'dayjs'

// Services
import { addBirthdayToContact } from 'services/contacts'

// Constants
const Event = Parse.Object.extend('Event')
const Collection = Parse.Object.extend('Collection')
const Contact = Parse.Object.extend('Contacts')
const Holiday = Parse.Object.extend('Holiday')


/**
  * @function createEvent
  * @param {object} contact of event
  * @param {object} holiday of event
  * @param {number} month of event
  * @param {number} day of event
  * @returns {Promise} event data
**/
export const createEvent = (contact, holiday, month, day) => {
  return new Promise((resolve, reject) => {
    const currentUser = Parse.User.current()
    if (!currentUser || !holiday || !contact || !month || !day) {
      reject(null)
    } else {
      let referenceHoliday = new Holiday()
        .set('objectId', holiday.id)
      if (holiday === 'Birthday') {
        referenceHoliday.set('objectId', 'BSoyX4waDn')
      }

      const recipientContact = new Contact()
        .set('objectId', contact.id)

      const newEvent = new Event()
        .set({
          month: month,
          day: day,
          holiday: referenceHoliday,
          contact: recipientContact,
          recipientString: contact.contactName
            ? contact.contactName : contact.get('contactName'),
          ownerId: currentUser.id,
          user: currentUser,
          should_notify: true,
          is_active: true,
          platform: 'web'
        })

      if (holiday && holiday.collection && holiday.collection.id) {
        const referenceCollection = new Collection()
          .set('objectId', holiday.collection.id)

        newEvent.set({ collection: referenceCollection })
      }

      newEvent.save()
        .catch(error => reject(error))
        .then(_newEvent => {
          if (holiday === 'Birthday' || holiday && holiday.name === 'Birthday') {
            addBirthdayToContact(contact.id, _newEvent.id)
              .catch(error => reject(error))
              .then(() => resolve(_newEvent))
          } else {
            resolve(_newEvent)
          }
        })
    }
  })
}


/**
  * @function createEventFromDate
  * @param {object} contact of event
  * @param {object} holiday of event
  * @param {date} date of event
  * @returns {Promise} event data
**/
export const createEventFromDate = (contact, holiday, date) => {
  return new Promise((resolve, reject) => {
    if (!holiday || !contact || !date) {
      reject()
    }
    createEvent(
      contact,
      holiday,
      parseInt(date.format('M'), 10),
      parseInt(date.format('D'), 10)
    )
      .catch(error => reject(error))
      .then(newEvent => resolve(newEvent))
  })
}


/**
  * @function createBirthdayEventFromDate
  * @param {object} contact of event
  * @param {date} date of event
  * @returns {Promise} event data
**/
export const createBirthdayEventFromDate = (contact, date) => {
  return new Promise((resolve, reject) => {
    if (!contact || !date) {
      reject(null)
    }
    const birthdayHoliday = new Holiday()
      .set({
        id: process.env.NODE_ENV === 'production' ? 'BSoyX4waDn' : 'fdlln21PFF',
        name: 'Birthday'
      })

    const month = parseInt(dayjs(date).format('M'), 10)
    const day = parseInt(dayjs(date).format('D'), 10)

    createEvent(contact, birthdayHoliday, month, day)
      .catch(error => reject(error))
      .then(_event => resolve(_event))
  })
}
