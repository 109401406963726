export const RESET_STATE = 'scenes/Contact/ImportContacts/reset_state'
export const START_FILE_PROCESSING =
  'scenes/Contact/ImportContacts/start_file_processing'
export const SET_COLUMNS = 'scenes/Contact/ImportContacts/set_columns'
export const UPDATE_ROW = 'scenes/Contact/ImportContacts/update_row'
export const UPDATE_COLUMN = 'scenes/Contact/ImportContacts/update_column'
export const MERGE_COLUMNS = 'scenes/Contact/ImportContacts/merge_columns'
export const CHANGE_COLUMN_NAME =
  'scenes/Contact/ImportContacts/change_column_name'
export const START_SUBMITING = 'scenes/Contact/ImportContacts/start_submiting'
export const CANCEL_SUBMITING = 'scenes/Contact/ImportContacts/cancel_submiting'
export const START_VERIFICATION =
  'scenes/Contact/ImportContacts/start_verification'
export const CANCEL_VERIFICATION =
  'scenes/Contact/ImportContacts/cancel_verification'
export const UPDATE_VALID_CONTACTS =
  'scenes/Contact/ImportContacts/update_valid_contacts'
export const HANDLE_ERROR = 'scenes/Contact/ImportContacts/handle_error'
export const POST_FINISHED = 'scenes/Contact/ImportContacts/post_finished'
export const CONTACT_FINISHED = 'scenes/Contact/ImportContacts/contact_finished'
