
export const POST_EXTRAS = [
  {
    label: 'specific-doodle',
    header: 'Request a Doodle',
    body: 'Let your artist know of a doodle you’d love to see in your finished card.',
    caption: '$3.50',
    image: require('img/extras/punkpost-specific-doodle.webp')
  },
  {
    label: 'glitter',
    header: 'Add Confetti',
    body: 'Sprinkle a little extra fun with a dash of confetti inside your card! One per card.',
    caption: '$1.00',
    image: require('img/extras/punkpost-confetti-v03-hand.webp')
  },
  {
    label: 'photo',
    header: 'Send Photo Squares',
    body: 'Add a high quality 4x4” print with your card.',
    caption: '$1.50 per print',
    image: require('img/extras/punkpost-print-photos.webp')
  },
  {
    label: 'giftCertificate',
    header: 'Add a Gift Certificate',
    body: `Include a gift certificate for your recipient to spend in big-box retailers.`,
    caption: '$5 to $250',
    image: require('img/extras/punkpost-gift-certificate.webp')
  },
  {
    label: 'is_secret_admirer',
    header: 'Send as a Secret Admirer',
    body:
      'Instead of a return address, our artist will write that your card is from a Secret Admirer.',
    caption: 'Free!',
    image: require('img/extras/punkpost-secret-admirer.webp')
  },
  {
    label: 'handDelivery',
    header: 'Deliver the Card Yourself',
    body: `Opt to deliver the card yourself.
      Your card order will be placed in a larger envelope and mailed directly to you.`,
    caption: '$1.50',
    image: require('img/extras/punkpost-hand-deliver.webp')
  }
]

export const DOODLE = {
  label: 'specific-doodle',
  header: 'Request a Doodle',
  body: 'Let your artist know of a doodle you’d love to see in your finished card.',
  caption: '$3.50',
  image: require('img/extras/punkpost-specific-doodle.webp')
}

export const CONFETTI = {
  label: 'glitter',
  header: 'Add Confetti',
  body: 'Sprinkle a little extra fun with a dash of confetti inside your card! One per card.',
  caption: '$1.00',
  image: require('img/extras/punkpost-confetti-v03-hand.webp')
}

export const PHOTOS = {
  label: 'photo',
  header: 'Send Photo Squares',
  body: 'Add a high quality 4x4” print with your card.',
  caption: '$1.50 per print',
  image: require('img/extras/punkpost-print-photos.webp')
}

export const GIFT_CERTIFICATE = {
  label: 'giftCertificate',
  header: 'Add a Gift Certificate',
  body: `Include a gift certificate for your recipient to spend in big-box retailers.`,
  caption: '$5 to $250',
  image: require('img/extras/punkpost-gift-certificate.webp')
}

export const SECRET_ADMIRER = {
  label: 'is_secret_admirer',
  header: 'Send as a Secret Admirer',
  body:
    'Instead of a return address, our artist will write that your card is from a Secret Admirer.',
  caption: 'Free!',
  image: require('img/extras/punkpost-secret-admirer.webp')
}

export const HAND_DELIVERY = {
  label: 'handDelivery',
  header: 'Deliver the Card Yourself',
  body: `Opt to deliver the card yourself.
    Your card order will be placed in a larger envelope and mailed directly to you.`,
  caption: '$1.50',
  image: require('img/extras/punkpost-hand-deliver.webp')
}
