
// Directory
export const SEARCH_BY_PARAMS = 'scenes/AddressBook/search_by_params'
export const CONTACTS_FETCHED = 'scenes/AddressBook/contacts_fetched'
export const SELECT_ALL_CHANGE =
  'scenes/AddressBook/contacts_fetched/select_all_change'
export const TOGGLE_CONTACT_DELETION =
  'scenes/AddressBook/contacts_fetched/toggle_contact_deletion'
export const RESET_SELECT_ALL = 'scenes/AddressBook/reset_select_all'
export const SHOW_CONTACTS_ERROR = 'scenes/AddressBook/show_contacts_error'

// Labels
export const PAGE_NAME = `Contacts`
export const IMPORT = `Import Contacts`
export const CREATE = `+ Create Contact`
