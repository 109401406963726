
export const ADD_RECIPIENT = 'scenes/Project/ProjectDetail/add_recipient'
export const START_LOADING =
  'scenes/Project/ProjectDetail/update_project_detail/start_loading'
export const UPDATE_PAGINATION =
  'scenes/Project/ProjectDetail/update_project_detail/update_pagination'
export const CHANGE_FILTER =
  'scenes/Project/ProjectDetail/update_project_detail/change_filter'
export const CHANGE_SEARCH =
  'scenes/Project/ProjectDetail/update_project_detail/change_search'
export const CHANGE_SORT_ORDER =
  'scenes/Project/ProjectDetail/update_project_detail/change_sort_order'
export const TOGGLE_POST_DELETION =
  'scenes/Project/ProjectDetail/update_project_detail/toggle_post_deletion'
export const UPDATE_PROJECT_DETAIL =
  'scenes/Project/ProjectDetail/update_project_detail'
export const UPDATE_POSTS =
  'scenes/Project/ProjectDetail/update_project_detail/update_posts'
export const UPDATE_NOTIFICATION =
  'scenes/Project/ProjectDetail/update_project_detail/update_notification'
export const SELECT_ALL_CHANGE =
  'scenes/Project/ProjectDetail/update_project_detail/select_all_change'
export const TOOGLE_ADD_RECIPIENT =
  'scenes/Project/ProjectDetail/update_project_detail/toogle_add_recipient'
export const HANDLE_FILTER_STATUS =
  'scenes/Project/ProjectDetail/update_project_detail/handle_filter_status'

export const DRAFT_STATUS = 'draft'
export const APPROVED_STATUS = 'paid'
export const SENT_STATUS = 'Sent'

export const FILTERS = {
  [DRAFT_STATUS]: ['draft', 'new'],
  [APPROVED_STATUS]: ['paid', 'scheduled', 'scripting'],
  [SENT_STATUS]: ['Sent']
}
