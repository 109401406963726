// -- COMBINE REDUCERS -- //

// Base
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

// Reducers
import account from 'reducers/account'
import cart from 'reducers/cart'
import favoriteCards from 'reducers/favoriteCards'
import project from 'reducers/project'

// Components
import addContact from 'components/Contact/InputContact/reducer'
import cardPicker from 'components/Pickers/CardPicker/reducer'
import creditCardForm from 'components/Payment/CreditCardForm/reducer'
import headerSearchBox from 'components/Atoms/Headers/SearchBox/reducer'
import notificationHandler from 'components/Notification/reducer'
import postSingleSend from 'components/PostSingleSend/reducer'

// Scenes
import addressBook from 'scenes/Contact/AddressBook/reducer'
import importContacts from 'scenes/Contact/ImportContacts/reducer'
import projectDetail from 'scenes/Project/ProjectDetail/reducer'
import projectSettings from 'scenes/Project/ProjectSettings/reducer'


/**
 * Combine all reducers from all components
 * @param { history } history of reducer
 * @return { combineReducers } from 'redux'
**/
export default function createReducer(history) {
  return combineReducers({
    cart,
    project,
    favoriteCards,
    cardPicker,
    account,
    projectDetail,
    addressBook,
    importContacts,
    notificationHandler,
    addContact,
    creditCardForm,
    postSingleSend,
    projectSettings,
    router: connectRouter(history),
    headerSearchBox
  })
}
