// -- DELETE -- //

// Base
import Parse from 'parse'

// Utils
import request from 'utils/request'

// Constants
import { USER_CARD } from 'constants'


export const deleteCard = cardId => {
  const user = Parse.User.current()
  return request(USER_CARD(user.id, cardId), {
    method: 'DELETE'
  })
}
