//
// -- PROFILE -- //
//


// Dependencies
import Parse from 'parse'

// Constants
const Profile = Parse.Object.extend('Profile')


// Sets the user.profile.phone property.
export const setPhoneNumber = number => {
  const currentUser = Parse.User.current()
  if (!currentUser) {
    return null
  }
  const profile = currentUser.get('profile')
    .set('phone', number)
  return profile.save()
}


// Sets a new user.profile
export const setClientProfile = user => {
  const profile = new Profile()
    .set({
      credits: 0,
      user: user,
      has_custom_cards: false
    })
  return profile.save()
}


/**
  * Retrieve profile data by id
  * @function getProfileData
  * @param {string} id from Profile
  * @returns {Promise} profile data
*/
export const getProfileData = id => {
  const query = new Parse.Query(Profile)
    .equalTo('objectId', id)

  return query.find(null)
}
