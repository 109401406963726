//
// -- Account Reducer Actions -- //
//


// Constants
import {
  RESET_DATA,
  TOGGLE_LOADING_CONTACTS,
  UPDATE_ACCOUNT,
  UPDATE_CONTACTS,
  UPDATE_NEW_NOTIFICATIONS
} from './constants'

// Services
import { countNewClientNotifications } from 'services/clientNotifications'
import { handleParseError } from 'services/parseErrors'
import { retrieveContacts } from 'services/contacts'

// Utils
import { retrieveUserProfile } from 'utils/auth'
import { getAttributesArray } from 'utils/parse'


// Reset

export const resetData = () => {
  return {
    type: RESET_DATA
  }
}


// Update

export const updateAccount = (contact, profile) => {
  return {
    type: UPDATE_ACCOUNT,
    contact,
    profile
  }
}

export const toggleLoadingContacts = isLoadingContacts => {
  return {
    type: TOGGLE_LOADING_CONTACTS,
    isLoadingContacts: isLoadingContacts
  }
}


export const updateContacts = contacts => {
  return {
    type: UPDATE_CONTACTS,
    contacts
  }
}


export const updateNewNotificationsCount = newNotifications => {
  return {
    type: UPDATE_NEW_NOTIFICATIONS,
    newNotifications
  }
}


//
// -- Refresh -- //
//

export const startLoadingContacts = (isLoadingContacts) => {
  return dispatch => {
    dispatch(toggleLoadingContacts(isLoadingContacts))
  }
}

export const refreshContacts = () => {
  return dispatch => {
    return retrieveContacts()
      .catch(error => {
        handleParseError(error)
      })
      .then(contacts => {
        if (contacts) {
          dispatch(toggleLoadingContacts(false))
          dispatch(updateContacts(getAttributesArray(contacts)))
        }
      })
  }
}


export const refreshNewNotificationsCount = () => {
  return dispatch => {
    return countNewClientNotifications()
      .catch(error => handleParseError(error))
      .then(newNotifications => {
        dispatch(updateNewNotificationsCount(newNotifications))
      })
  }
}


export const refreshProfile = () => {
  return dispatch => {
    return retrieveUserProfile()
      .catch(error => handleParseError(error))
      .then(account => {
        if (account) {
          dispatch(updateAccount(account.contact, account.profile))
        }
      })
  }
}
