//
// -- FETCH Contacts -- //
//
// Created by Jonatan Santa Cruz on 2017.
// Copyright 2023 Punkpost Inc.
//


// Base
import Parse from 'parse'
import dayjs from 'dayjs'

// Reference Objects
const Contact = Parse.Object.extend('Contacts')
const Event = Parse.Object.extend('Event')
const includePointers = [
  'birthday', 'birthday.holiday', 'birthday.user', 'birthday.workingPost',
  'birthday.contact', 'birthday.holiday.collection'
]

/**
  * Retrieve contact data by id
  * @function getContactData
  * @param {string} contact_id from Contact
  * @returns {Promise} contact data
*/
export const getContactData = contact_id => {
  const query = new Parse.Query(Contact)
    .include(includePointers)

  return query.get(contact_id)
}

/**
  * Retrieve contact data by ids
  * @function getContactData
  * @param {string} contact_ids from Contact
  * @returns {Promise} array of contact objects
*/
export const getContactObjectsById = contact_ids => {
  let contactPromises = []
  for (let id in contact_ids) {
    if (Object.prototype.hasOwnProperty.call(contact_ids, id)) {
      contactPromises = [...contactPromises, getContactData(contact_ids[id])]
    }
  }
  return Promise.all(contactPromises)
}


/**
  * Retrieve user contacts
  * @function getUserContacts
  * @param {string} value from contactName search
  * @returns {Promise} contact data
*/
export const getUserContacts = (value = '') => {
  const user = Parse.User.current()
  const nameQuery = user.relation('contacts').query()
    .matches('contactName', value, 'i')

  const companyQuery = user.relation('contacts').query()
    .matches('company', value, 'i')

  const emailQuery = user.relation('contacts').query()
    .matches('email', value, 'i')

  const jobQuery = user.relation('contacts').query()
    .matches('jobTitle', value, 'i')

  const departmentQuery = user.relation('contacts').query()
    .matches('companyDepartment', value, 'i')

  const addressQuery = user.relation('contacts').query()
    .matches('contactAddress', value, 'i')

  const cityQuery = user.relation('contacts').query()
    .matches('contactCity', value, 'i')

  const stateQuery = user.relation('contacts').query()
    .matches('contactState', value, 'i')

  const zipQuery = user.relation('contacts').query()
    .matches('contactZip', value, 'i')

  const countryQuery = user.relation('contacts').query()
    .matches('country', value, 'i')

  const mainQuery = Parse.Query.or(
    nameQuery, emailQuery,
    companyQuery, jobQuery, departmentQuery,
    addressQuery, cityQuery, stateQuery, zipQuery, countryQuery
  )
    .ascending('contactName')
    .notEqualTo('is_active', false)
    .include(includePointers)
    .limit(200000)

  return mainQuery.find()
}


export const retrieveContacts = () => {
  const user = Parse.User.current()
  if (!user) {
    return new Promise((resolve) => {
      resolve([])
    })
  }
  const query = user.relation('contacts').query()
    .exists('contactName')
    .ascending('contactName')
    .notEqualTo('is_active', false)
    .notEqualTo('isSender', true)
    .include(includePointers)
    .limit(200000)

  return query.find()
}

export const getSenderContacts = () => {
  const user = Parse.User.current()
  const query = user.relation('contacts').query()
    .exists('contactName')
    .ascending('contactName')
    .notEqualTo('is_active', false)
    .equalTo('isSender', true)
    .equalTo('ownerId', user.id)
    .include(includePointers)

  return query.find()
}


export const getReturnAddressContacts = () => {
  const user = Parse.User.current()
  const query = user.relation('contacts').query()
    .equalTo('isProfile', true)
    .include(includePointers)
  return Promise.all([query.find(), getSenderContacts()])
}


export const getEventsByBirthdayMonth = monthInput => {
  const allMonths = [
    'january', 'february', 'march', 'april',
    'may', 'june', 'july', 'august',
    'september', 'october', 'november', 'december'
  ]
  if (allMonths.includes(monthInput.toLowerCase())) {
    const month = parseInt(
      dayjs().month(monthInput).format('M'), 10
    )

    const currentUser = Parse.User.current()
    let query = new Parse.Query(Event)
      .equalTo('user', currentUser.id)
      .equalTo('month', month)
    return query.find()
  }

  return new Promise(resolve => {
    resolve()
  })
}

export const getContactsByArrayOfIds = events => {
  let contactsQuery = []
  events.forEach(event => {
    const contactId = event.get('contactIds')[0]
    if (contactId) {
      contactsQuery = [...contactsQuery, getContactData(contactId)]
    }
  })
  return new Promise((resolve, reject) => {
    Promise.all(contactsQuery)
      .catch(error => {
        console.warn(error)
        reject(error)
      })
      .then(contacts => {
        resolve(contacts)
      })
  })
}

export const fetchSenderContacts = () => {
  const user = Parse.User.current()
  const query = new Parse.Query(Contact)
    .equalTo('ownerId', user.id)
    .equalTo('isSender', true)
    .ascending('contactName')
    .notEqualTo('is_active', false)
  return query.find()
}
