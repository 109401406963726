//
// -- Prices Utils -- //
//


// Base
import _cloneDeep from 'lodash/cloneDeep'
import { fromJS } from 'immutable'

// Utils
import { countCharsEmojis } from 'utils/text'
import { isInternational } from 'utils/addresses'

// Services
import { fetchTotalUserCredits } from 'services/users'


// Default
export const defaultSummary = {
  cards: {
    count: 0,
    amount: 0
  },
  confetti: {
    count: 0,
    amount: 0
  },
  photos: {
    count: 0,
    amount: 0
  },
  gift_certificates: {
    count: 0,
    amount: 0
  },
  international: {
    count: 0,
    amount: 0
  },
  extraCharacters: {
    count: 0,
    amount: 0
  },
  handDelivery: {
    count: 0,
    amount: 0
  },
  specificDoodle: {
    count: 0,
    amount: 0
  },
  subtotal_without_gift_certificates: {
    amount: 0
  },
  subtotal: {
    amount: 0
  },
  credits: {
    amount: 0
  },
  total: 0
}

export const computePostPrice = (
  post,
  destination = 'United States',
  prices = {},
  giftCertificate = null
) => {
  // For some reason we can't use isImmutable check to avoid this cast

  let _post = fromJS(post)
  const card = _post.get('cardPointer')
  const cardPrice = card ? card.get('price') : null
  const basePrice = cardPrice ? cardPrice.get('price') : 7.75
  const exceedsMaxChars = countCharsEmojis(_post && _post.get('Message', '') || '').chars >
    (cardPrice.get('extraCharacterLimit') || 0)
  const extraCharactersPrice = exceedsMaxChars ?
    cardPrice.get('extraCharacterPrice') : 0
  const price = basePrice + extraCharactersPrice
  const hasGlitter = _post.get('glitter')
  const hasSpecificDoodle = _post.get('shouldIncludeSpecificDoodle')
  const photos = _post.get('photos')
  const photoPrice = prices.photoSquare || 0
  const _isInternational = destination ? isInternational(destination) : false
  const isHandDelivery = _post.get('handDelivery')

  const confettiPrice = hasGlitter ? prices.glitter || 0 : 0
  const specificDoodlePrice = hasSpecificDoodle ? prices.specificDoodle || 0 : 0
  const internationalPrice = _isInternational ? prices.international || 0 : 0
  const photosPrice = photos ? photos.size * photoPrice : 0
  const handDeliveryPrice = isHandDelivery ? prices.handDelivery || 0 : 0
  const hasGiftCertificate = giftCertificate ? true : false
  const giftCertificateTotal = giftCertificate ? (
    giftCertificate.amount
    + giftCertificate.bank_fee
    + giftCertificate.punkpost_fee
  ) : 0

  return {
    basePrice: basePrice,
    price: price,
    total:
      price +
      confettiPrice +
      internationalPrice +
      photosPrice +
      handDeliveryPrice +
      specificDoodlePrice +
      giftCertificateTotal,
    extraCharacters: {
      exceeds: exceedsMaxChars,
      amount: exceedsMaxChars ? extraCharactersPrice : 0
    },
    confetti: {
      has: hasGlitter,
      amount: confettiPrice
    },
    photos: {
      count: photos ? photos.size : 0,
      amount: photosPrice
    },
    gift_certificate: {
      has: hasGiftCertificate,
      amount: giftCertificate ? giftCertificate.total : 0
    },
    international: {
      is: _isInternational,
      amount: internationalPrice
    },
    handDelivery: {
      has: isHandDelivery,
      amount: handDeliveryPrice
    },
    specificDoodle: {
      has: hasSpecificDoodle,
      amout: specificDoodlePrice
    }
  }
}

export const _computeCreditsAmount = subtotal => {
  const totalUserCredits = fetchTotalUserCredits()
  return totalUserCredits > subtotal ? subtotal : totalUserCredits
}


/*
 * Compute price of each post and summarize each post price with count of
 * how many posts have glitter, photos, are international, have hand delivery or exceed max chars.
 * @param {Immutable.Map} posts: Map with in the form of {postId: {...post}}
 * @param {Object} prices: JS Object with keys: {photoSquare, glitter, international}
 */
export const computeSummary = (posts, prices, giftCertificates) => {
  const summary = _cloneDeep(defaultSummary)
  posts.forEach(post => {
    const recipient = post.get('recipient')
    const gift_certificate_id = post.get('gift_certificate_id')
    const destination = recipient ? recipient.get('country') : 'United States'
    const price = computePostPrice(post, destination, prices)

    // eslint-disable-next-line
    summary.cards.amount += price.basePrice // eslint-disable-next-line
    summary.cards.count += 1 // eslint-disable-next-line
    summary.confetti.amount += price.confetti.amount // eslint-disable-next-line
    summary.confetti.count += price.confetti.has ? 1 : 0 // eslint-disable-next-line
    summary.photos.amount += price.photos.amount // eslint-disable-next-line
    summary.photos.count += price.photos.count // eslint-disable-next-line
    summary.gift_certificates.count += gift_certificate_id ? 1 : 0 // eslint-disable-next-line
    summary.international.amount += price.international.amount // eslint-disable-next-line
    summary.international.count += price.international.is ? 1 : 0 // eslint-disable-next-line
    summary.extraCharacters.amount += price.extraCharacters.amount // eslint-disable-next-line
    summary.extraCharacters.count += price.extraCharacters.exceeds ? 1 : 0 // eslint-disable-next-line
    summary.handDelivery.amount += price.handDelivery.amount // eslint-disable-next-line
    summary.handDelivery.count += price.handDelivery.has ? 1 : 0 // eslint-disable-next-line
    summary.specificDoodle.amount += price.specificDoodle.amout // eslint-disable-next-line
    summary.specificDoodle.count += price.specificDoodle.has ? 1 : 0 // eslint-disable-next-line
  })

  if (giftCertificates && giftCertificates.length) {
    giftCertificates.forEach(giftCertificate => {
      // eslint-disable-next-line
      const total = giftCertificate ? (
        giftCertificate.amount
        + giftCertificate.bank_fee
        + giftCertificate.punkpost_fee
      ) : 0
      summary.gift_certificates.amount = summary.gift_certificates.amount + total
    })
  }

  summary.subtotal =
    summary.cards.amount +
    summary.confetti.amount +
    summary.photos.amount +
    summary.gift_certificates.amount +
    summary.international.amount +
    summary.extraCharacters.amount +
    summary.handDelivery.amount +
    summary.specificDoodle.amount

  summary.subtotal_without_gift_certificates =
    summary.cards.amount +
    summary.confetti.amount +
    summary.photos.amount +
    summary.international.amount +
    summary.extraCharacters.amount +
    summary.handDelivery.amount +
    summary.specificDoodle.amount

  summary.credits.amount = _computeCreditsAmount(summary.subtotal_without_gift_certificates)

  summary.total = summary.subtotal - summary.credits.amount

  return fromJS({ ...summary })
}


export const computeSinglePostTotal = (post, form, prices) => {
  // Card Price
  const cardPrice = post.card.price && post.card.price.getAttributes() || post.card.get('price')

  // Extra Characters
  const exceedsExtraChars = form.messageCounter.chars > form.price.extraCharacterLimit
  const priceForExtraChars = prices.extraCharacters
    ? prices.extraCharacters : 1
  const extraCharactersTotal = exceedsExtraChars ? priceForExtraChars : 0

  // Doodle
  const priceForSpecificDoodle = prices.specificDoodle ? prices.specificDoodle : 0.69
  const specificDoodleTotal = post.shouldIncludeSpecificDoodle ? priceForSpecificDoodle : 0

  return cardPrice.price + extraCharactersTotal + specificDoodleTotal
}
