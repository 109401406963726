import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from './constants'

export const showNotificaction = (text = '', notificationType = 'primary') => {
  return {
    type: SHOW_NOTIFICATION,
    text,
    notificationType
  }
}

export const hideNotificaction = () => {
  return {
    type: HIDE_NOTIFICATION
  }
}
