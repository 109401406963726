//
// -- LogoAtom -- //
//
// Created by Santiago Prieto on Nov 12, 2022.
// Copyright 2023 Punkpost Inc.
//


// Base
import React from 'react'
import BaseComponent from 'utils/BaseComponent'
import './styles.less'

// Constants
const LOGO = require('img/general/punkpost-logo.png')
const LOGO_WEBP = require('img/general/punkpost-logo.webp')


// Base Component
export default class LogoAtom extends BaseComponent {
  render() {
    return (
      <div className="LogoAtom">
        <picture>
          <source type="image/webp" srcSet={LOGO_WEBP} />
          <img src={LOGO} className="LogoAtom__Image" alt="Punkpost" />
        </picture>
      </div>
    )
  }
}
