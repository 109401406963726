//
// -- Landing Page for New User -- //
//
// Created by Jonatan Santa Cruz on 2017.
// Copyright 2023 Punkpost Inc.
//


// Base
import React from 'react'
import BaseComponent from 'utils/BaseComponent'

// Local Components
import HeroContainer from './HeroContainer'
import HeroReview from './HeroReview'
import SecondaryContainer from './SecondaryContainer'
// import FreeConfetti from './FreeConfetti'
import BenefitsSection from 'components/Landing/BenefitsSection'
import TieredPricing from 'components/Promotion/TieredPricing'
import EndCallToAction from './EndCallToAction'

// import FreeConfettiBanner from 'components/Promotion/FreeConfettiBanner'

// Constants
import { REVIEW } from './constants'


// Base Component
export default class NewUser extends BaseComponent {
  //
  // -- Render -- //
  //

  render() {
    return (
      <div className="LandingHome__Container">
        <HeroContainer/>
        <SecondaryContainer/>
        {
          // <FreeConfettiBanner/>
        }
        <BenefitsSection/>
        <HeroReview review={REVIEW}/>
        <TieredPricing showShopButton={false}/>
        <EndCallToAction/>
      </div>
    )
  }
}
