//
// -- UPDATE User Services-- //
//


// Dependencies
import Parse from 'parse'

// Utils
import request from 'utils/request'

// Constants
const Contact = Parse.Object.extend('Contacts')
import { USER_EMAIL } from 'constants'


/**
  * @function saveAccountData
  * @param {string} username of user
  * @param {string} phoneNumber of user
  * @returns {Promise} user data
**/
export const saveAccountData = (username, phoneNumber) => {
  return new Promise((resolve, reject) => {
    const user = Parse.User.current()
      .set({
        username: username,
        email: username,
        phoneNumber: phoneNumber
      })
    user.save()
      .catch(error => reject(error))
      .then(() => resolve(request(USER_EMAIL(user.id), { method: 'PUT' })))
  })
}

/**

  * @function putAccountInactive
  * @param {string} username of user
  * @param {string} phoneNumber of user
  * @returns {Promise} user data
**/
export const putAccountInactive = () => {
  return new Promise((resolve, reject) => {
    const username = Parse.User.current().attributes.username
    const user = Parse.User.current()
      .set({
        username: `${username}zzz`,
        email: `${username}zzz`,
        isActive: false
      })
    user.save()
      .catch(error => reject(error))
      .then(() => resolve(request(USER_EMAIL(user.id), { method: 'PUT' })))
  })
}


/**
  * @function setMyContact
  * @param {string} contact_id of contact
  * @returns {Promise} user data
**/
export const setMyContact = (contact_id) => {
  return new Promise((resolve, reject) => {
    new Contact()
      .set({
        objectId: contact_id,
        isSender: true
      })
      .save()
      .catch(error => reject(error))
      .then((contact) => {
        const user = Parse.User.current()
        if (user) {
          user
            .set({
              myContact: contact
            })
            .relation('contacts').add(contact)
            .save()
            .catch(error => reject(error))
            .then(()=> resolve(null))
        } else {
          reject(null)
        }
      })
  })
}

/**
  * @function saveUserData
  * @param {string} contactData of user
  * @param {string} profileImage of user
  * @returns {Promise} user data
**/
export const saveUserData = (contactData, profileImage = null) => {
  const currentUser = Parse.User.current()
  const contact = currentUser.get('myContact')

  if (contact !== null && contact.attributes.createdAt) {
    // user.myContact exists
    if (currentUser.get('platforms')) {
      if (currentUser.get('platforms').includes('web') === false) {
        currentUser.set({ platforms: [...currentUser.get('platforms'), 'web'] })
        currentUser.save()
      }
    } else {
      currentUser.set({ platforms: ['web'] })
      currentUser.save()
    }

    contact.set({
      company: contactData.company,
      contactAddress: contactData.contactAddress,
      contactCity: contactData.contactCity,
      contactName: contactData.contactName,
      contactState: contactData.contactState,
      contactZip: contactData.contactZip,
      country: contactData.country,
      email: contactData.email,
      unit: contactData.unit
    })
    currentUser.set({
      fullName: contactData.contactName
    })
    if (profileImage) {
      contact.set('profilePic', profileImage)
    }
    contact.save()
    currentUser.set('myContact', contact)
  } else {
    // Create New Contact
    const newContact = new Contact()
      .set({
        company: contactData.company,
        contactAddress: contactData.contactAddress,
        contactCity: contactData.contactCity,
        contactName: contactData.contactName,
        contactState: contactData.contactState,
        contactZip: contactData.contactZip,
        country: contactData.country,
        email: contactData.email,
        unit: contactData.unit
      })
    if (profileImage) {
      newContact.set('profilePic', profileImage)
    }
    newContact.save()
    currentUser.set('myContact', newContact)
  }
  return currentUser.save()
}


/**
  * @function updateBusinessProfile
  * @param {string} data of user
  * @returns {Promise} user data
**/
export const updateBusinessProfile = data => {
  const currentUser = Parse.User.current()
  const profile = currentUser.get('profile')
    .set('businessType', data.business_type)

  const contact = currentUser.get('myContact')
    .set({
      contactName: data.owner_name,
      email: data.email,
      company: data.company_name,
      contactAddress: data.street_address,
      unit: data.apt_unit_floor,
      contactCity: data.city,
      contactState: data.state,
      contactZip: data.zip
    })

  currentUser.set({
    fullName: data.owner_name,
    myContact: contact,
    profile: profile
  })
  return currentUser.save()
}


/**
  * @function setDefaultPaymentCard
  * @param {string} id of payment card
  * @returns {Promise} user data
**/
export const setDefaultPaymentCard = (id = '') => {
  if (id === '') {
    return null
  }
  const currentUser = Parse.User.current()
    .set('defaultCard', id)
  return currentUser.save()
}

/**
  * @function setDidFinishTour
  * @param {Boolean} didFinish of payment card
  * @returns {Promise} user data
**/
export const setDidFinishTour = (didFinish = false) => {
  if (didFinish === null) {
    return null
  }
  const currentUser = Parse.User.current()
  if (currentUser === null) {
    return null
  }
  currentUser.set({ didFinishTour: didFinish })
  return currentUser.save()
}


/**
  * @function setDidFinishProjectsTour
  * @param {Boolean} didFinish of payment card
  * @returns {Promise} user data
**/
export const setDidFinishProjectsTour = (didFinish = false) => {
  if (didFinish === null) {
    return null
  }
  const currentUser = Parse.User.current()
  if (currentUser === null) {
    return null
  }
  currentUser.set({ didFinishProjectsTour: didFinish })
  return currentUser.save()
}


/**
  * @function updateBillingMethod
  * @param {string} method of payment card
  * @returns {Promise} user data
**/
export const updateBillingMethod = method => {
  const profile = Parse.User.current().get('profile')
    .set('billingMethod', method)
  return profile.save()
}


export const changePassword = () => {
  const email = Parse.User.current().get('username')
  if (!email) {
    return new Promise(reject => reject())
  }
  return Parse.User.requestPasswordReset(email)
}


/**
  * @function setLastActive
  * @returns {Promise} user data
**/
export const setLastActive = () => {
  Parse.User.current()
    .set('lastActive', new Date())
    .save()
}


/**
  * @function setLastSent
  * @returns {Promise} user data
**/
export const setLastSent = () => {
  Parse.User.current()
    .set('lastSent', new Date())
    .save()
}
