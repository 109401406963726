//
// -- Create CardSearchTerm Parse Services -- //
//
// Created by Santiago Prieto on June 2022.
// Copyright 2023 Punkpost Inc.
//


// Base
import Parse from 'parse'

// Referece Objects
const CardSearchTerm = Parse.Object.extend('CardSearchTerm')


/**
  * @function createCardSearchTerm
  * @param {string} searchTerm inputed by user
  * @param {number} resultAmount from search query
  * @returns {null}
**/
export const createCardSearchTerm = (searchTerm, resultAmount) => {
  if (
    searchTerm === '' || searchTerm === null || searchTerm === ' ' ||
    searchTerm === 'queryundefined'
  ) {
    return
  }
  const newCardSearchTerm = new CardSearchTerm()
    .set({
      searchTerm: searchTerm.toLowerCase().trim(),
      platform: 'web',
      resultAmount: resultAmount
    })
    const user = Parse.User.current()
    if (user) {
      newCardSearchTerm.set('user', user)
    }
    newCardSearchTerm.save()
}
