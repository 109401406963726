//
// -- SignupView -- //
//
//
// Created by Santiago Prieto on Oct 31, 2022.
// Copyright 2023 Punkpost Inc.
//


// Base
import React from 'react'
import BaseComponent from 'utils/BaseComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { withRouter } from 'react-router-dom'
import { reverse } from 'routes'
import './style.less'

// Utils
import { verifyEmailIsUser } from 'utils/auth'
import { validateEmail } from 'utils/text'

// Components
import Notification from './Notification'

// Constants
const INSTRUCTIONS = 'Ready to send cards? Enter your email to create your account.'
const BUTTON_TEXT = 'Get Started'
const ERROR_EMAIL = 'Please enter an email.'
const ERROR_VALID_EMAIL = 'Please enter a valid email.'


// Base Component
class EmailSignupForm extends BaseComponent {
  constructor() {
    super()
    this.state = {
      form: {
        email: '',
        shouldFade: false
      },
      goToSignUp: true,
      notification: {
        visible: false,
        message: ''
      },
      buttonDisabled: false
    }

    this._bind(
      '_onChange', '_onSubmit', '_handleSuccess',
      '_handleNotificationClose'
    )
  }


  //
  // -- handlers -- //
  //

  _onChange({ target }) {
    const value = target.name === 'email' ? target.value.toLowerCase() : target.value
    this.setState(prevState => {
      return {
        form: {
          ...prevState.form,
          [target.name]: value
        },
        notification: {
          visible: false,
          message: ''
        },
        buttonDisabled: false
      }
    })
  }

  _onSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    this.setState({ buttonDisabled: true })
    const form = this.state.form
    if (!form.email) {
      this.setState({
        notification: {
          visible: true,
          message: ERROR_EMAIL
        },
        buttonDisabled: false
      })
      return
    }
    if (!validateEmail(form.email)) {
      this.setState({
        notification: {
          visible: true,
          message: ERROR_VALID_EMAIL
        },
        buttonDisabled: false
      })
      return
    }

    verifyEmailIsUser(form.email)
      .catch(() => {
        this.setState(prevState => {
          return {
            form: {
              ...prevState.form,
              shouldFade: true
            },
            goToSignUp: true
          }
        })
      })
      .then(isUser => {
        this.setState(prevState => {
          return {
            form: {
              ...prevState.form,
              shouldFade: true
            },
            goToSignUp: isUser ? false : true
          }
        })
      })
  }

  _handleSuccess() {
    if (this.state.goToSignUp) {
      this.props.history.push(
        `${reverse('auth-new-account')}?email=${this.state.form.email}`
      )
    } else {
      this.props.history.push(
        `${reverse('auth-signin')}?email=${this.state.form.email}`
      )
    }
  }

  _handleNotificationClose() {
    this.setState({
      notification: {
        visible: false,
        message: ''
      }
    })
  }

  _goToLogIn(e) {
    if (e) {
      e.preventDefault()
    }
    this.props.history.push(reverse('auth-signin'))
  }


  //
  // -- Render -- //
  //

  render() {
    return (
      <form
        className={this.state.form.shouldFade
          ? 'EmailSignupForm__Form Fade' : 'EmailSignupForm__Form'
        }
        onSubmit={this._onSubmit}
        onAnimationEnd={this._handleSuccess}
      >
        <h3>{INSTRUCTIONS}</h3>
        <div className="EmailSignupForm__Form__Inputs">
          <input
            autoComplete="email"
            className="EmailSignupForm__Form__Inputs__Field"
            maxLength="50"
            name="email"
            type="email"
            onChange={this._onChange}
            placeholder="Email address"
            value={this.state.form.email}
          />
          <button
            className="EmailSignupForm__Form__Inputs__Submit"
            type="submit"
          >
            {BUTTON_TEXT}
            <FontAwesomeIcon
              icon={faChevronRight}
              className="EmailSignupForm__Form__Inputs__Submit__Icon"
            />
          </button>
        </div>
        <Notification
          isVisible={this.state.notification.visible}
          message={this.state.notification.message}
          onClose={this._handleNotificationClose}
        />
      </form>
    )
  }
}

export default withRouter(EmailSignupForm)
