//
// -- SEARCH Cards-- //
//


// Base
import Parse from 'parse'

// Services
import { createCardSearchTerm } from 'services/cardSearchTerm'

// Constants
const Cards = new Parse.Object('Cards')
const Price = new Parse.Object('Price')
const WORDS_NOT_ALLOWED = [
  'card', 'cards', 'what to write',
  'script', 'scripts', 'messages', 'message',
  'for a', 'what to put on a', ' to your',
  'query', 'queryundefined', 'for your'
]

export const searchCards = searchTerm => {
  return new Promise((resolve, reject) => {
    const _searchTerm = searchTerm
      .toLowerCase()
      .replace(/[^a-zA-Z0-9 ]/g, '')

    const cleanSearchTerm = WORDS_NOT_ALLOWED
      .reduce((result, word) => result.replace(word, ''), _searchTerm)
      .trim()

    const nameQuery = new Parse.Query(Cards)
      .matches('cardName', cleanSearchTerm, 'i')
      .equalTo('status', true)
      .notEqualTo('isPrivate', true)
      .notEqualTo('isCancelled', true)
      .notEqualTo('isActive', false)
      .include(['price', 'cardArtist'])

    const categoryQuery = new Parse.Query(Cards)
      .matches('cardCategory', cleanSearchTerm, 'i')
      .equalTo('status', true)
      .notEqualTo('isPrivate', true)
      .notEqualTo('isCancelled', true)
      .notEqualTo('isActive', false)
      .include(['price', 'cardArtist'])

    const tagsQuery = new Parse.Query(Cards)
      .containsAllStartingWith('tags', cleanSearchTerm, 'i')
      .equalTo('status', true)
      .notEqualTo('isPrivate', true)
      .notEqualTo('isCancelled', true)
      .notEqualTo('isActive', false)
      .include(['price', 'cardArtist'])

    const mainQuery = new Parse.Query.or(
      nameQuery, categoryQuery, tagsQuery
    )
    mainQuery.find()
      .catch(error => {
        console.warn(error)
        reject(error)
      })
      .then(cards => {
        const _cards = []
        if (cards) {
          createCardSearchTerm(cleanSearchTerm, cards.length)
          cards.map(card => {
            if (card && !card.getAttributes().price.getAttributes().price) {
              // TODO: Get price from parse and add to card manually.
              const query = new Parse.Query(Price)
                .equalTo('objectId', card.getAttributes().price.id)

              query.first()
                .then(price => {
                  card.price = price
                })
            }
            _cards.push(card)
          })
        }
        resolve(_cards)
      })
  })
}
