// -- FavoriteCards Reducer Actions -- //

// Constants
import {
  RESET_DATA,
  UPDATE_FAVORITE_CARDS,
  SET_FAVORITE_CARD,
  REMOVE_FAVORITE_CARD
} from './types'

// Services
import { queryFavoriteCards } from 'services/cards'
import { handleParseError } from 'services/parseErrors'


// Actions

export const resetData = () => {
  return {
    type: RESET_DATA
  }
}


export const updateFavoriteCards = cardIds => {
  return {
    type: UPDATE_FAVORITE_CARDS,
    favoriteCardIds: cardIds
  }
}


export const setCardAsFavorite = cardId => {
  return {
    type: SET_FAVORITE_CARD,
    cardId: cardId
  }
}


export const removeCardAsFavorite = cardId => {
  return {
    type: REMOVE_FAVORITE_CARD,
    cardId: cardId
  }
}


export const refreshFavoriteCards = () => {
  return dispatch => {
    return queryFavoriteCards()
      .catch(error => handleParseError(error))
      .then(favoriteCards => {
        if (favoriteCards && favoriteCards.length > 0) {
          let _cardIds = []
          favoriteCards.forEach(favoriteCard => {
            const _favoriteCard = favoriteCard.getAttributes()
            const _cardId = _favoriteCard.card.getAttributes().id
            _cardIds = [_cardId, ..._cardIds]
          })
          dispatch(updateFavoriteCards(_cardIds))
        }
      })
  }
}
