//
// -- Favorites SubRoutes -- //
//
// Created by Santiago Prieto on October 9, 2019
// Copyright Punkpost Inc. 2019
//


// Utils
import { lazy } from 'react'

// Project Scenes
const FavoriteCards = lazy(() => import(
  /* webpackChunkName: "FavoriteCards" */
  'scenes/Card/FavoriteCards'
))

// Project Scenes
const MyFavoriteArtists = lazy(() => import(
  /* webpackChunkName: "MyFavoriteArtists" */
  'scenes/Artist/Favorites'
))


// Routes
export default {
  cards: {
    path: '/cards',
    component: FavoriteCards
  },
  artists: {
    path: '/artists',
    component: MyFavoriteArtists
  }
}
