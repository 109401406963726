//
// -- MainLanding Scene -- //
//
// Copyright 2023 Punkpost Inc.
//


// Base
import React from 'react'
import BaseComponent from 'utils/BaseComponent'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { reverse } from 'routes/index'
import './styles.less'

// Utils
import { isAuthenticated } from 'utils/auth'
// import { triggerHotjarEvent } from 'utils/hotjar'

// Components
import PageAlert from 'components/Atoms/PageAlert'
import Footer from 'components/Atoms/Footer'
import NewUser from './NewUser'

// Constants
import { HELMET } from 'constants'
// const HOTJAR_NEW_USER_EVENT = 'new_user_homepage'


// Base Component
class MainLanding extends BaseComponent {
  constructor() {
    super()
    this.state = {
      shouldShowPageAlert: false,
      width: window.innerWidth
    }
    this._bind(
      '_handleWindowSizeChange'
    )
  }


  // Component Config

  componentDidMount() {
    if (isAuthenticated()) {
      this.props.history.push(reverse('authenticated_landing'))
    }
    // triggerHotjarEvent(HOTJAR_NEW_USER_EVENT)
  }

  componentDidUpdate() {
    window.addEventListener('resize', this._handleWindowSizeChange)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._handleWindowSizeChange)
  }


  //
  // -- Handlers -- //
  //

  _handleWindowSizeChange() {
    this.setState({ width: window.innerWidth })
  }


  //
  // -- Render -- //
  //

  _renderHelmet() {
    const URL = 'https://punkpost.com'
    return (
      <Helmet>
        <title>{`${HELMET.appName} | Beautifully Handwritten by Artists for You`}</title>
        <link rel="canonical" href={URL}/>
        <meta name="title" content={
          `${HELMET.appName} | Beautifully Handwritten by Artists for You`
          }
        />
        <meta name="description" content={HELMET.appDescription}/>
        <meta property="og:title" content={HELMET.appName}/>
        <meta property="og:description" content={HELMET.appDescription}/>
        <meta property="og:site_name" content={HELMET.appName}/>
        <meta property="og:image" content={HELMET.appCoverImage} />
        <meta property="og:url" content={URL} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={HELMET.appTwitter} />
      </Helmet>
    )
  }

  render() {
    const isMoble = this.state.width <= 500

    return (
      <div className="LandingHome">
        {this._renderHelmet()}
        {this.state.shouldShowPageAlert && <PageAlert/>}
        <NewUser
          sections={this.state.sections}
          handleCollection={this._handleCollection}
          goToCardShop={this._goToCardShop}
          isMoble={isMoble}
          context={this.context}
        />
        <Footer/>
      </div>
    )
  }
}

export default withRouter(MainLanding)
