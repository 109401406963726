//
// -- Parse Config -- //
//


// Base
import Parse from 'parse'


// Fetches the config at most once every 4 hours per app runtime.
export const refreshConfig = () => {
  let lastFetchedDate = null
  const configRefreshInterval = 4 * 60 * 60 * 1000
  const currentDate = new Date()
  if (lastFetchedDate === null ||
      currentDate.getTime() - lastFetchedDate.getTime() > configRefreshInterval) {
    Parse.Config.get()
    lastFetchedDate = currentDate
  }
}


export const getCurrentOfferTitle = () => {
  return new Promise((resolve) => {
    Parse.Config.get()
      .catch(() => resolve())
      .then(config => {
        if (config) {
          resolve(config.get('offerTitle') || null)
        }
      })
    })
}


export const getSaleBannerText = () => {
  return new Promise(resolve => {
    Parse.Config.get()
      .catch(() => {
        resolve(null)
      })
      .then(config => {
        if (config) {
          resolve(config.get('saleBanner') || null)
        }
      })
    })
}

export const getSaleBannerURL = () => {
  return new Promise(resolve => {
    Parse.Config.get()
      .catch(() => {
        resolve(null)
      })
      .then(config => {
        if (config) {
          resolve(config.get('saleBannerURL') || null)
        }
      })
    })
}


export const getArtistApplicationStatus = () => {
  return new Promise((resolve, reject) => {
    Parse.Config.get()
      .catch(error => {
        console.warn(error)
        reject(null)
      })
      .then(config => {
        if (config) {
          const status = config.get('artistApplicationStatus')
          resolve(status || false)
        }
      })
    })
}


export const getFreeConfettiOnFirstOrderStatus = () => {
  return new Promise((resolve, reject) => {
    Parse.Config.get()
      .catch(error => {
        console.warn(error)
        reject(null)
      })
      .then(config => {
        if (config) {
          const status = config.get('freeConfettiOnFirstOrder')
          resolve(status || false)
        }
      })
    })
}
