//
// -- BenefitsSection for Landing Component -- //
//
//
// Created by Santiago Prieto on Nov 13, 2022.
// Copyright 2023 Punkpost Inc.
//


// Base
import React from 'react'
import BaseComponent from 'utils/BaseComponent'
import './styles.less'

const BENEFITS = [{
  header: 'A handwriting artist makes it',
  body: `With Punkpost you can easily send handwritten cards without having
  to worry about  the details.`,
  image: require('img/general/punkpost-handwriting.mov'),
  flexDirection: 'row'
}, {
  header: `Tell us exactly what to doodle`,
  body: `Take your card to the next level with a Doodle for $3.50.
  Do you want a rainbow farting unicorn? A dog party? Maybe champagne and
  flowers for the happy couple?`,
  image: require('img/general/punkpost-specific-doodles.mp4'),
  flexDirection: 'row-reverse'
}, {
  header: `Your recipient will adore it`,
  body: `After sending over half a million custom cards we are
  sure your recipient will love it.`,
  image: require('img/general/punkpost-mailbox.mov'),
  flexDirection: 'row'
}]


// -- Base Component -- //
export default class BenefitsSection extends BaseComponent {
  _renderBenefit(benefit) {
    return (
      <div
        className="BenefitsSection__Benefit"
        style={{ flexDirection: benefit.flexDirection }}
        key={benefit.header}
      >
        <div className="BenefitsSection__Benefit__Image">
          <video src={benefit.image} alt={benefit.header} autoPlay loop muted/>
        </div>
        <div className="BenefitsSection__Benefit__Info">
          <h4>{benefit.header}</h4>
          <p>{benefit.body}</p>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="BenefitsSection">
        {BENEFITS.map(benefit => this._renderBenefit(benefit))}
      </div>
    )
  }
}
