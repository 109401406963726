//
// --  Pinterest Tag Wrapper -- //
//
// Created by Santiago Prieto on March 7, 2022
// Copyright 2023 Punkpost Inc.
//

//
// REFERENCE Pinterest docs: https://developers.pinterest.com/docs/tag/conversion/
// Based on https://github.com/jlvdh/react-pinterest-tag
//


// Dependencies
import Parse from 'parse'


// Variables
let initialized = false
let debug = true


// Constants
const verifyInit = () => {
  if (!initialized) {
    console.warn('[pinterest-tracking] Tag not initialized.')
  }
  return initialized
}
const log = (...args) => {
  console.log(...['[pinterest-tracking]'].concat(args))
}
const defaultOptions = {
  debug: false
}


// Base Code
export default {
  init(uniqueTagId, options = defaultOptions) {
    const userEmail = Parse.User.current() && Parse.User.current().getAttributes().username
    const em = {
      em: userEmail
    }

    /* eslint-disable */
      !function(e){if(!window.pintrk){window.pintrk = function () {
        window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
        n=window.pintrk;n.queue=[],n.version="3.0";var
        t=document.createElement("script");t.async=!0,t.src=e;var
        r=document.getElementsByTagName("script")[0];
        r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
    /* eslint-enable */

    if (!uniqueTagId) {
      console.warn('[pinterest-tracking] Please insert unique id for initializing.')
    } else {
      pintrk('load', uniqueTagId, userEmail ? em : {}) // eslint-disable-line no-undef
      initialized = true
      debug = options.debug
    }
  },

  pageView() {
    if (!verifyInit()) {
      return
    }

    pintrk('page') // eslint-disable-line no-undef

    if (debug) {
      log('called pintrk(\'page\')')
    }
  },

  track(title, data) {
    if (!verifyInit()) {
      return
    }

    pintrk('track', title, data) // eslint-disable-line no-undef

    if (debug) {
      log(`called fbq('track', '${title}');`)
      if (data) {
        log('with data', data)
      }
    }
  },

  pintrk(...args) {
    if (!verifyInit()) {
      return
    }

    pintrk(...args) // eslint-disable-line no-undef

    if (debug) {
      log(`called pintrk('${args.slice(0, 2).join('\', \'')}')`)

      if (args[2]) {
        log('with data', args[2])
      }
    }
  },

  pageVisit(card) {
    if (!verifyInit() || !card) {
      return
    }
    const data = {
      line_items: [{
        product_name: card.cardName,
        product_id: card.id
      }]
    }
    pintrk('track', 'pagevisit', data) // eslint-disable-line no-undef
  },

  addToCart(card) {
    if (!verifyInit() || !card) {
      return
    }
    const data = {
      order_quantity: 1,
      line_items: [{
        product_name: card.cardName,
        product_id: card.id,
        product_quantity: 1
      }]
    }
    pintrk('track', 'AddToCart', data) // eslint-disable-line no-undef
  },

  purchase(post) {
    if (!verifyInit() || !post) {
      return
    }
    const data = {
      value: post.totalPay,
      order_quantity: 1,
      order_id: post.id,
      currency: 'USD',
      line_items: [{
        product_name: post.Card,
        product_id: post.cardId,
        product_price: post.totalPay,
        product_quantity: 1
      }]
    }
    pintrk('track', 'checkout', data) // eslint-disable-line no-undef
  }
}
