export const HAND_DELIVER = {
  emoji: '🤲',
  title: 'Send to Myself',
  price_desc: '$1.50 for extra writing and envelope.',
  description: `The card will be sent to your address so that you can delivery it
  by hand or add it to a gift.`,
  not_available: `This option is not available for premium cards.`
}

export const DELIVERY_CONFIRMATION_ENTER_EMAIL = {
  header: `Add your recipient's email to request a delivery confirmation.`,
  body: `We will then send your recipient an email message to ask if they've
  gotten your card yet. If they say yes then you will be notified.`,
  button: 'Request Delivery Confirmation'
}


export const SHIPPING = {
  header: `Shipping`,
  prep: `Card is mailed tomorrow or on the date of your chosing.`,
  delivery: `Current Estimated Delivery:`,
  details: [
    `Delivery is based on purchase date and recipient location.`,
    `Your card will be in the mail about 24hrs after purchase.`,
    `You may also schedule a card in advanced.`,
    `Ships from your artist's location in the USA.`,
    `First Class Mail is handled by US Postal Service.`,
    `International postage is available.`
  ]
}


export const HOW_IT_WORKS = {
  header: `How it Works`,
  details: [
    `Pick a card (like this one).`,
    `Add your message to be handwritten by an artist.`,
    `Tell us where to send it.`,
    `Boost your card with extras (optional)`,
    `Hit send and relax - we will mail the card for you.`,
    `Your recipient will love it.`
  ]
}
