export const SET_RECIPIENT = 'components/PostSingleSend/recipient_select'
export const SET_DEFAULT_CARD = 'components/PostSingleSend/set_default_card'
export const SET_EDIT_SENDER = 'components/PostSingleSend/set_edit_sender'
export const SET_EVENT = 'components/PostSingleSend/set_event'
export const SET_SENDER_CONTACT = 'components/PostSingleSend/set_sender_contact'
export const SET_NEXT_BACK_VIEWS = 'components/PostSingleSend/set_next_back_views'
export const SET_CONTACT_ID = 'components/PostSingleSend/set_contact_id'
export const RESET_STORE = 'components/PostSingleSend/reset_store'
export const UPDATE_EXTRA_PHOTOS = 'components/PostSingleSend/update_extra_photos'
export const UPDATE_PRICES = 'components/PostSingleSend/update_prices'
export const UPDATE_POST = 'components/PostSingleSend/update_post'
export const UPDATE_GIFT = 'components/PostSingleSend/update_gift'
export const FORCE_NEXT = 'components/PostSingleSend/force_next'
