//
// -- Accounts SubRoutes -- //
//
// Created by Jonatan Santa Cruz on 2018
// Copyright 2023 Punkpost Inc.
//


// Utils
import { lazy } from 'react'

// Containers
const App = lazy(() => import(
  /* webpackChunkName: "App" */
  'containers/App')
)
const Clean = lazy(() => import(
  /* webpackChunkName: "Clean" */
  'containers/Clean'
))

// Authentication Scenes
const RecoverPassword = lazy(() => import(
  /* webpackChunkName: "RecoverPassword" */
  'scenes/Authentication/RecoverPassword'
))

// Profile Scenes
const ClientProfile = lazy(() => import(
  /* webpackChunkName: "ClientProfile" */
  'scenes/Account/ClientProfile'
))
const AccountSettings = lazy(() => import(
  /* webpackChunkName: "AccountSettings" */
  'scenes/Account/AccountSettings'
))
const PaymentMethod = lazy(() => import(
  /* webpackChunkName: "PaymentMethod" */
  'scenes/Account/PaymentMethod'
))
const InvoiceDetail = lazy(() => import(
  /* webpackChunkName: "InvoiceDetail" */
  'scenes/Account/InvoiceDetail'
))
const AddCreditCard = lazy(() => import(
  /* webpackChunkName: "AddCreditCard" */
  'scenes/Account/AddCreditCard'
))
const EnterPromoCode = lazy(() => import(
  /* webpackChunkName: "EnterPromoCode" */
  'scenes/Account/EnterPromoCode'
))

// Upcoming Events
const UpcomingEvents = lazy(() => import(
  /* webpackChunkName: "UpcomingEvents" */
  'scenes/Account/UpcomingEvents'
))

// Custom Cards
const CustomStationery = lazy(() => import(
  /* webpackChunkName: "CustomStationery" */
  'scenes/Account/CustomStationery'
))


// Routes
export default {
  custom_stationery: {
    path: '/custom-stationery',
    container: App,
    component: CustomStationery
  },
  password_recovery: {
    path: '/password/recover',
    loginRequired: false,
    component: RecoverPassword
  },
  profile: {
    path: '/profile',
    container: App,
    component: ClientProfile,
    routes: {
      settings: {
        path: '/settings',
        component: AccountSettings
      },
      payment: {
        path: '/payment',
        component: PaymentMethod,
        routes: {
          invoice: {
            path: '/invoice/:id',
            component: InvoiceDetail
          }
        }
      }
    }
  },
  promo_code: {
    path: '/enter-promo-code',
    container: App,
    component: EnterPromoCode
  },
  upcoming_events: {
    path: '/upcoming-cards',
    container: App,
    component: UpcomingEvents
  },
  cards: {
    path: '/new-payment-method',
    container: Clean,
    component: AddCreditCard
  }
}
