import { fromJS } from 'immutable'

import { RESET_STATE, UPDATE_INPUT } from './constants'

export const initialState = fromJS({
  value: '',
  placeholder: 'Search cards',
  filters: false,
  action: () => {}
})

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_INPUT:
      return state.set(action.field, action.value)
    case RESET_STATE:
      return initialState
    default:
      return state
  }
}
