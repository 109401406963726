//
// -- UPDATE Contact -- //
//
// Created by Jonatan Santa Cruz on 2017.
// Copyright 2023 Punkpost Inc.
//


// Base
import Parse from 'parse'
import dayjs from 'dayjs'

// Utils
import request from 'utils/request'

// Reference Objects
const Contact = Parse.Object.extend('Contacts')
  const Event = Parse.Object.extend('Event')

// Constants
import { REQUEST_ADDRESS } from 'constants'


export const updateContact = (contactData, profilePicture = null) => {
  return new Promise((resolve, reject) => {
    const contact = new Contact()
      .set({
        objectId: contactData.id,
        contactName: contactData.contactName,
        company: contactData.company,
        jobTitle: contactData.jobTitle,
        companyDepartment: contactData.companyDepartment,
        email: contactData.email,
        contactAddress: contactData.contactAddress,
        country: contactData.country,
        contactCity: contactData.contactCity,
        contactState: contactData.contactState,
        contactZip: contactData.contactZip,
        unit: contactData.unit,
        lastVerification: dayjs().toDate()
      })

    if (profilePicture) {
      contact.set('profilePic', profilePicture)
    }

    contact.save()
      .catch(error => reject(error))
      .then(newContact => resolve(newContact.getAttributes()))
  })
}


export const updateContactEmail = (contactId, email) => {
  const contact = new Contact()
    .set({
      objectId: contactId,
      email: email
    })
  return contact.save()
}


export const addMyContactObject = contact => {
  const mycontact = new Contact()
    .set({
      company: contact.company,
      contactAddress: contact.contactAddress,
      contactCity: contact.contactCity,
      contactName: contact.name,
      contactState: contact.contactState,
      contactZip: contact.contactZip,
      country: contact.country,
      unit: contact.unit
    })

  const currentUser = Parse.User.current()
    .set('myContact', mycontact)

  return currentUser.save()
}


//
// -- Event Pointers -- //
//

export const addBirthdayToContact = (contactId, eventId) => {
  if (!contactId) {
    return null
  }
  const event = new Event()
    .set({ objectId: eventId })

  const contact = new Contact()
    .set({
      objectId: contactId,
      birthday: event
    })
  return contact.save()
}

export const removeBirthdayFromContact = contactId => {
  if (!contactId) {
    return null
  }
  const contact = new Contact()
    .set({
      objectId: contactId,
      birthday: null
    })
  return contact.save()
}

export const addAnniversaryToContact = (contactId, eventId) => {
  const event = new Event()
    event.set({ objectId: eventId })

  const contact = new Contact()
    .set({
      objectId: contactId,
      anniversary: event
    })
  return contact.save()
}

export const removeAnniversaryFromContact = contactId => {
  const contact = new Contact()
    .set({
      objectId: contactId,
      birthday: null
    })
  return contact.save()
}


export const updateContactRelationship = (contactId, relationship) => {
  const contact = new Contact()
    .set({
      objectId: contactId,
      relationship: relationship
    })
  return contact.save()
}


//
// -- API Endpoints -- //
//

export const setRequestAddress = (token, formData) => {
  return request(REQUEST_ADDRESS(token), {
    method: 'PUT',
    isParse: false,
    body: {
      address: formData.contactAddress,
      city: formData.contactCity,
      zip_code: formData.contactZip,
      apartment: formData.unit,
      state: formData.contactState,
      country: formData.country,
      company: formData.company
    }
  })
}


export const validateRequestAddressToken = (token) => {
  return request(REQUEST_ADDRESS(token), { isParse: false })
}
