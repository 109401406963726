// -- MainLanding Constants -- //

export const PRESS_LOGOS = [
  {
    name: 'Refinery29',
    image: require('img/press-badges/logo-refinery-29.webp'),
    png: require('img/press-badges/logo-refinery-29.png')
  },
  {
    name: 'Washington Post',
    image: require('img/press-badges/logo-washington-post.webp'),
    png: require('img/press-badges/logo-washington-post.png')
  },
  {
    name: 'Forbes',
    image: require('img/press-badges/logo-forbes.webp'),
    png: require('img/press-badges/logo-forbes.png')
  },
  {
    name: 'Elle',
    image: require('img/press-badges/logo-elle.webp'),
    png: require('img/press-badges/logo-elle.png')
  },
  {
    name: 'ABC',
    image: require('img/press-badges/logo-abc.webp'),
    png: require('img/press-badges/logo-abc.png')
  },
  {
    name: 'Goop',
    image: require('img/press-badges/logo-goop.webp'),
    png: require('img/press-badges/logo-goop.png')
  }
]

export const REVIEW = {
  quote: `"So incredibly beautiful. I love sending these
  cards just to see what the artists come up with!"`,
  author: `Gloria B.`
}
