/* This file is mainly used to define constants that contains API endpoints
  * API constants here must follow this format, ending with forward-slash:
  *
  * >> export const {OBJECT}_ULR = `${API_BASE_URL}/objects/`
  *
  * shouldn't include HTTP Verb:
  * constants can be mixed or functions e.g:
  *
  * WRONG: >> export const UPDATE_CLIENT_URL = `${API_BASE_URL}/clients/:client_id/`
  * OK: >> export const CLIENT_URL = (CLIENT_ID) => `${API_BASE_URL}/clients/${CLIEN_ID}/`
  *
  * only a really bad API design could lead to breaking these rules 🙃
  *
**/


//
// -- Import -- //
//

import {
  USER_CARD, USER_CARDS_URL, USER_WELCOME_EMAIL_URL, CLIENT_PROMOCODE_URL, USER_EMAIL,
  REDEEM_GIFT_CERTIFICATE_URL, GIFT_CERTIFICATE_URL, GIFT_CERTIFICATE_ACTIVE_URL,
  COMMUNICATIONS_CONTACT_US_URL, CUSTOM_CARD_CREATE_URL,
  UPLOAD_CONTACTS_CSV_URL, VERIFY_IMPORTED_CONTACTS_URL, CREATE_VERIFIED_CONTACTS_URL,
  RECIPIENTS_CSV_URL, REQUEST_ADDRESS, ORDERS_URL,
  PORTFOLIO_PHOTO_LIKE, WRITER_FAVORITE,
  INVOICES_URL, INVOICES_ACTIVE_URL, INVOICE_DETAIL_URL, INVOICE_PAY,
  INVOICES_REMOVE_POST, INVOICE_ACTIVITY_LOG,
  OFFER_NEWSLETTER_SIGNUP, CORPORATE_NEWSLETTER_SIGNUP,
  POST_MISTAKE, POST_NEW_COMMENT, POST_NEW_RATING,
  POST_DELIVERY_CONFIRMATION_REQUEST,
  POST_DELIVERY_CONFIRMATION_SUCCESS, POST_DELIVERY_CONFIRMATION_DENIAL,
  POST_RECIPIENT_COMMENT, POST_RECEIPT,
  PROJECT_GIFT_CERTIFICATES, PROJECT_SHARE
} from './ApiEndpoints'
import { PUNKPOST_BENEFITS } from './Benefits'
import { REVIEWS } from './Reviews'
import { PRODUCT_LINKS, SUPPORT_LINKS, LEGAL_LINKS } from './Footer'
import { POST_EXTRAS } from './FunExtras'
import { HELMET } from './Helmet'
import { DELIVERY_CONFIRMATION_ENTER_EMAIL, SHIPPING } from './Post'


//
// -- EXPORT -- //
//

export {
  USER_CARD, USER_CARDS_URL, USER_WELCOME_EMAIL_URL, CLIENT_PROMOCODE_URL, USER_EMAIL,
  REDEEM_GIFT_CERTIFICATE_URL, GIFT_CERTIFICATE_URL, GIFT_CERTIFICATE_ACTIVE_URL,
  COMMUNICATIONS_CONTACT_US_URL, CUSTOM_CARD_CREATE_URL,
  UPLOAD_CONTACTS_CSV_URL, VERIFY_IMPORTED_CONTACTS_URL, CREATE_VERIFIED_CONTACTS_URL,
  RECIPIENTS_CSV_URL, REQUEST_ADDRESS, ORDERS_URL,
  PORTFOLIO_PHOTO_LIKE, WRITER_FAVORITE,
  INVOICES_URL, INVOICES_ACTIVE_URL, INVOICE_DETAIL_URL, INVOICE_PAY,
  INVOICES_REMOVE_POST, INVOICE_ACTIVITY_LOG,
  OFFER_NEWSLETTER_SIGNUP, CORPORATE_NEWSLETTER_SIGNUP,
  POST_MISTAKE, POST_NEW_COMMENT, POST_NEW_RATING,
  POST_DELIVERY_CONFIRMATION_REQUEST,
  POST_DELIVERY_CONFIRMATION_SUCCESS, POST_DELIVERY_CONFIRMATION_DENIAL,
  POST_RECIPIENT_COMMENT, POST_RECEIPT,
  PROJECT_GIFT_CERTIFICATES, PROJECT_SHARE,
  HELMET,
  LEGAL_LINKS,
  POST_EXTRAS,
  PRODUCT_LINKS,
  PUNKPOST_BENEFITS, REVIEWS,
  SUPPORT_LINKS,
  DELIVERY_CONFIRMATION_ENTER_EMAIL, SHIPPING
}


//
// -- App Environment Variables -- //
//

// This API url changes based on staging vs production.
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL
export const PARSE_SERVER_URL = process.env.REACT_APP_PARSE_SERVER_URL
export const PARSE_JS_ID = process.env.REACT_APP_PARSE_JS_ID
export const PARSE_APP_ID = process.env.REACT_APP_PARSE_APP_ID
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY
export const BRANCH_KEY = process.env.REACT_APP_BRANCH_KEY


//
// -- App Constants -- //
//

// Marketing Constants
export const MKT_HANDWRITING_WEBPAGE = 'https://punkpost.com/stories/handwriting-styles'
export const IOS_APPSTORE_URL =
  `https://itunes.apple.com/app/apple-store/id939096403?pt=111313804&amp;ct=Footer&amp;mt=8`

// General Constants
export const LOGIN_ERROR = 'Error! Invalid email.'
export const BUSINESS_TYPES = [
  {
    value: 'Events (e.g. planners, weddings, parties, events)',
    label: 'Events (e.g. planners, weddings, parties, events)'
  },
  {
    value: 'Online Marketplace (e.g. Etsy, Amazon, Yelp)',
    label: 'Online Marketplace (e.g. Etsy, Amazon, Yelp)'
  },
  {
    value: 'Service Agency (e.g. real estate, insurance)',
    label: 'Service Agency (e.g. real estate, insurance)'
  },
  {
    value: 'Professional (e.g. blogger, designer, chef)',
    label: 'Professional (e.g. blogger, designer, chef)'
  },
  {
    value: 'Public Figure (e.g. politician, athlete, musician)',
    label: 'Public Figure (e.g. politician, athlete, musician)'
  },
  {
    value: 'Media (e.g. magazine, newspaper, tv)',
    label: 'Media (e.g. magazine, newspaper, tv)'
  },
  {
    value: 'Retailer (e.g. Anthropologie, Pottery Barn)',
    label: 'Retailer (e.g. Anthropologie, Pottery Barn)'
  },
  {
    value: 'Tech Services (e.g. IT, Saas, Hardware)',
    label: 'Tech Services (e.g. IT, Saas, Hardware)'
  },
  {
    value: 'Local Business (e.g. restaurant, boutique)',
    label: 'Local Business (e.g. restaurant, boutique)'
  },
  {
    value: 'Institution/Non-Profit (e.g. Smithsonian, Moma)',
    label: 'Institution/Non-Profit (e.g. Smithsonian, Moma)'
  },
  { value: 'Other', label: 'Other' }
]


export const COMPANY_LINKS = [
  {
    name: 'Our Artist Community',
    url: 'https://punkpost.com/artists/'
  },
  {
    name: 'Become a Handwriting Artist',
    url: 'https://punkpost.com/artist-application'
  },
  {
    name: 'About Punkpost',
    url: 'https://punkpost.com/stories/about-us'
  },
  {
    name: 'Pricing',
    url: 'https://punkpost.com/pricing'
  },
  {
    name: 'Our Blog',
    url: 'https://punkpost.com/stories'
  },
  {
    name: 'Press',
    url: 'https://punkpost.co/press'
  }
]


export const SOCIAL_LINKS = [
  {
    name: 'Instagram',
    url: 'https://instagram.com/punkpostco'
  },
  {
    name: 'Facebook',
    url: 'https://facebook.com/punkpostco'
  },
  {
    name: 'Pinterest',
    url: 'https://pinterest.com/punkpost'
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/punkpostco'
  }
]
