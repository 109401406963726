import { fromJS } from 'immutable'

import {
  CANCEL_SUBMITING,
  CANCEL_VERIFICATION,
  CHANGE_COLUMN_NAME,
  MERGE_COLUMNS,
  RESET_STATE,
  SET_COLUMNS,
  START_FILE_PROCESSING,
  START_SUBMITING,
  START_VERIFICATION,
  UPDATE_COLUMN,
  UPDATE_ROW,
  UPDATE_VALID_CONTACTS,
  HANDLE_ERROR,
  POST_FINISHED,
  CONTACT_FINISHED
} from './constants'

/*
 * columnIDs field will be use only for initial rendering, won't be modified at any point
*/

export const initialState = fromJS({
  postFinished: false,
  contactFinished: false,
  isProcessingFile: false,
  isSubmitting: false,
  importToProject: false,
  initialized: false,
  showNotification: false,
  recordsToVerify: 0,
  validRecords: 0,
  contactsVerified: false,
  contactsToCreate: [],
  project: {},
  columns: {},
  defaultColumnsName: [
    { name: 'email', display_name: 'Email Address' },
    { name: 'address_type', display_name: 'Address Type' },
    { name: 'notes', display_name: 'Notes' },
    { name: 'message', display_name: 'Message' },
    { name: 'phone', display_name: 'Phone' },
    { name: 'city', display_name: 'City' },
    { name: 'country', display_name: 'Country' },
    { name: 'apt_unit', display_name: 'Apt / Unit' },
    { name: 'zip', display_name: 'Zip' },
    { name: 'state', display_name: 'State' },
    { name: 'signature', display_name: 'Signature' }
  ],
  columnIDs: [],
  hasError: false,
  allColumns: { columns: {} }
})

export default function reducer(state = initialState, action) {
  let columns
  let column
  let columnIDs
  switch (action.type) {
    case CONTACT_FINISHED:
      return state.set('contactFinished', action.status)
    case POST_FINISHED:
      return state.set('postFinished', action.status)
    case HANDLE_ERROR:
      return state
        .set('isProcessingFile', false)
        .set('initialized', false)
        .set('project', fromJS({}))
        .set('hasError', true)
    case START_FILE_PROCESSING:
      return state
        .set('isProcessingFile', true)
        .set('initialized', true)
        .set('importToProject', action.importToProject)
        .set('project', fromJS(action.project))
        .set('hasError', false)
    case SET_COLUMNS:
      const firstColumnId = Object.keys(action.columns)[0]
      const firstColumn = action.columns[firstColumnId]
      const recordsToVerify = firstColumn
        ? Object.keys(firstColumn.rows).length
        : 0
      const _defaultColumnsName = state
        .get('defaultColumnsName')
        .filter(dcn => !action.columnNames[dcn.get('name')])
      return state
        .set('isProcessingFile', false)
        .set('columns', fromJS(action.columns))
        .set('columnIDs', fromJS(action.columnIDs))
        .set('defaultColumnsName', _defaultColumnsName)
        .set('recordsToVerify', recordsToVerify)
        .set('contactsVerified', action.isVerification)
        .set('showNotification', action.isVerification)
        .set('allColumns', fromJS({ columns: action.columns }))
    case UPDATE_VALID_CONTACTS:
      let contactsToCreate = state
        .get('contactsToCreate')
        .concat(fromJS(action.contacts))
      return state
        .set('contactsToCreate', contactsToCreate)
        .set('validRecords', contactsToCreate.size)
    case UPDATE_COLUMN:
      columns = state.get('columns')
      column = columns.get(action.id)
      column = column.set(action.name, action.value)
      return state.set('columns', columns.set(action.id, column))
    case UPDATE_ROW:
      columns = state.get('columns')
      column = columns.get(action.columnId)
      let rows = column.get('rows')
      let row = rows.get(action.id)
      row = row.set(action.name, action.value)
      rows = rows.set(action.id, row)
      column = column.set('rows', rows)
      columns = columns.set(action.columnId, column)
      return state.set('columns', columns)
    case MERGE_COLUMNS:
      columns = state.get('columns')
      const sourceRows = columns.get(action.source).get('rows')
      let destinationColumn = columns.get(action.destination)
      let destinationRows = destinationColumn.get('rows')

      /*
        * Merge values from source column to destination column
      */

      destinationRows = destinationRows.map((_row, key) => {
        const sourceValue = sourceRows.get(key).get('value')
        const value = _row.get('value')
        return _row.set('value', `${value} ${sourceValue}`)
      })

      /*
        * Update column in columns Object
      */

      destinationColumn = destinationColumn.set('rows', destinationRows)
      columns = columns.set(action.destination, destinationColumn)

      /*
        * Remove columnID from list of columnIDs to delete this columns from UI
      */

      columnIDs = state.get('columnIDs')
      const sourceIndex = columnIDs.indexOf(action.source)
      columnIDs = columnIDs.delete(sourceIndex)

      /*
        * Apply changes to state
      */

      return state.set('columns', columns).set('columnIDs', columnIDs)
    case CHANGE_COLUMN_NAME:
      /*
        * If that name already exists avoid computation and just return same state
      */

      columnIDs = state.get('columnIDs')
      columns = state.get('columns')

      /*
        * Change name in selected column
      */

      const columnName = state
        .get('defaultColumnsName')
        .find(c => c.get('name') === action.name)
      const displayName = columnName.get('display_name')
      column = columns
        .get(action.columnId)
        .set('name', action.name)
        .set('display_name', displayName)
        .set('skipped', false)
      columns = columns.set(action.columnId, column)

      /*
        * Remove selected name from default options
      */

      const defaultColumnsName = state
        .get('defaultColumnsName')
        .filter(dcn => dcn.get('name') !== action.name)

      /*
      * Each column should have its own ID non-related with its name
      * to avoid re-rendering rows after name changes
      */

      return state
        .set('columns', columns)
        .set('defaultColumnsName', defaultColumnsName)
    case START_SUBMITING:
      return state.set('isSubmitting', true)
    case CANCEL_SUBMITING:
      return state.set('isSubmitting', false)
    case START_VERIFICATION:
      return state.set('isVerifying', true)
    case CANCEL_VERIFICATION:
      return state.set('isVerifying', false)
    case RESET_STATE:
      return initialState
    default:
      return state
  }
}
