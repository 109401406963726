//
// -- HeroSection for Landing Component -- //
//
//
// Created by Santiago Prieto on Oct 31, 2022.
// Copyright 2023 Punkpost Inc.
//


// Base
import React from 'react'
import BaseComponent from 'utils/BaseComponent'
import { withRouter } from 'react-router-dom'
import { reverse } from 'routes'
import './styles.less'

// Components
import LogoAtom from 'components/Atoms/LogoAtom'

// Constants
import { LANDING } from 'constants/Landing'
const BUTTON_SIGNIN = 'Sign In'


// -- Base Component -- //
class HeroSection extends BaseComponent {
  constructor() {
    super()
    this._bind('_handleGoToSignIn')
  }

  _handleGoToSignIn() {
    this.props.history.push(reverse('auth-signin'))
  }


  //
  // -- Render -- //
  //

  _renderTagline() {
    return (
      <div className="HeroSection__Container__Tagline">
        <h1>
          {LANDING.tagline}
        </h1>
      </div>
    )
  }

  _renderSignIn() {
    return (
      <button
        className="HeroSection__SignIn"
        onClick={this._handleGoToSignIn}
      >
        {BUTTON_SIGNIN}
      </button>
    )
  }

  render() {
    return (
      <div className="HeroSection">
        <LogoAtom/>
        {this._renderSignIn()}
        <div className="HeroSection__Container">
          <div className="HeroSection__Container__MaxWidth">
            <div className="HeroSection__Container__Left">
              {this._renderTagline()}
              <div className="HeroSection__Container__Left__Form">
                {this.props.children}
              </div>
            </div>
            <div className="HeroSection__Container__Right">
              <picture>
                <source type="image/webp" srcSet={LANDING.heroImageWebp} />
                <img src={LANDING.heroImage}
                  className="HeroSection__Container__Right__HeroImage"
                  alt="Punkpost"
                />
                <div className="HeroSection__Container__Right__Form">
                  {this.props.children}
                </div>
              </picture>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(HeroSection)
