// -- Count CLIENT NOTIFICATIONS -- //

// Base
import Parse from 'parse'

// Reference Objects
const ClientNotification = Parse.Object.extend('ClientNotification')


export const countNewClientNotifications = () => {
  const user = Parse.User.current()
  if (!user) {
    return null
  }
  const query = new Parse.Query(ClientNotification)
    .equalTo('recipient_user', user)
    .notEqualTo('is_read', true)
    .notEqualTo('is_active', false)
  return query.count()
}
