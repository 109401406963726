// -- API Endpoints -- //

export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL


// -- API USER URL Constants -- //
export const USER_CARDS_URL = userId => `${BASE_API_URL}/users/${userId}/cards/`
export const USER_CARD = (userId, cardId) =>
  `${BASE_API_URL}/users/${userId}/cards/${cardId}/`
export const ORDERS_URL = `${BASE_API_URL}/orders/`
export const USER_WELCOME_EMAIL_URL = userId =>
  `${BASE_API_URL}/clients/${userId}/welcome/`
export const CLIENT_PROMOCODE_URL = (userId, promoCodeValue) =>
  `${BASE_API_URL}/clients/${userId}/promo-codes/${promoCodeValue}/`
export const USER_EMAIL = (userId) => `${BASE_API_URL}/users/${userId}/email/`

// -- API COMMUNICATIONS URLs -- Endpoint Constants -- //
export const COMMUNICATIONS_CONTACT_US_URL = `${BASE_API_URL}/communications/support/`


// -- API CONTACTS URL - Endpoint Constant -- //
export const UPLOAD_CONTACTS_CSV_URL = `${BASE_API_URL}/contacts/validate/csv/`
export const VERIFY_IMPORTED_CONTACTS_URL = `${BASE_API_URL}/contacts/validate/json/`
export const CREATE_VERIFIED_CONTACTS_URL = `${BASE_API_URL}/contacts/bulk/`
export const REQUEST_ADDRESS = token =>
  `${BASE_API_URL}/contacts/address-request/${token}/`


// -- API CUSTOM CARDS URL - Endpoint Constant -- //
export const CUSTOM_CARD_CREATE_URL = `${BASE_API_URL}/custom-stationery/invite/`


// -- API GIFT CERTIFICATE URL - Endpoint Constant -- //
export const REDEEM_GIFT_CERTIFICATE_URL = `${BASE_API_URL}/posts/gift-certificates/redeem/`
export const GIFT_CERTIFICATE_URL = postId =>
  `${BASE_API_URL}/posts/${postId}/gift-certificate/`
export const GIFT_CERTIFICATE_ACTIVE_URL = (postId, giftId) =>
  `${GIFT_CERTIFICATE_URL(postId)}${giftId}`


// -- API INVOICE URL - Endpoint Constant -- //
export const INVOICES_URL = () => `${BASE_API_URL}/invoices/`
export const INVOICES_ACTIVE_URL = () => `${BASE_API_URL}/invoices/active/`
export const INVOICE_DETAIL_URL = id => `${BASE_API_URL}/invoices/${id}/`
export const INVOICE_PAY = () => `${BASE_API_URL}/invoices/active/payment/`
export const INVOICES_REMOVE_POST = post_object_id =>
  `${BASE_API_URL}/invoices/active/posts/${post_object_id}/`
export const INVOICE_ACTIVITY_LOG = invoice_id =>
  `/invoices/${invoice_id}/tasks/`


// -- API OFFERS URL - Endpoint Constant -- //
export const OFFER_NEWSLETTER_SIGNUP = `${BASE_API_URL}/offers/newsletter-signup/`
export const CORPORATE_NEWSLETTER_SIGNUP = `${BASE_API_URL}/offers/corporate-newsletter-signup/`


// -- API POSTS URL - Endpoint Constant -- //
export const POST_DELIVERY_CONFIRMATION_REQUEST = post_id =>
  `${BASE_API_URL}/posts/${post_id}/notifications/delivery-confirmation-request/`
export const POST_DELIVERY_CONFIRMATION_DENIAL = post_id =>
  `${BASE_API_URL}/posts/${post_id}/notifications/recipient-denial/`
export const POST_DELIVERY_CONFIRMATION_SUCCESS = post_id =>
  `${BASE_API_URL}/posts/${post_id}/notifications/recipient-confirmation/`
export const POST_MISTAKE = post_id =>
    `${BASE_API_URL}/posts/${post_id}/notifications/mistake/`
export const POST_NEW_COMMENT = post_id =>
  `${BASE_API_URL}/posts/${post_id}/notifications/new-comment/`
export const POST_NEW_RATING = post_id =>
  `${BASE_API_URL}/posts/${post_id}/notifications/new-rating/`
export const POST_RECIPIENT_COMMENT = post_id =>
  `${BASE_API_URL}/posts/${post_id}/notifications/recipient-comment/`
export const POST_RECEIPT = post_id =>
  `${BASE_API_URL}/posts/${post_id}/receipt/`

  // -- API PROJECTS URL - Endpoint Constant -- //
export const PROJECT_GIFT_CERTIFICATES = project_id =>
  `${BASE_API_URL}/projects/${project_id}/gift-certificates/`
export const PROJECT_SHARE = (project_id, user_id) =>
  `${BASE_API_URL}/projects/${project_id}/share/${user_id}/`

// -- API RECIPIENTS URL - Endpoint Constant -- //
export const RECIPIENTS_CSV_URL = id => `${BASE_API_URL}/projects/${id}/recipients/csv/`


// -- API WRITERS URL - Endpoing Constant -- //
export const PORTFOLIO_PHOTO_LIKE = photo_id =>
  `${BASE_API_URL}/writers/portfolio-photos/${photo_id}/notifications/like/`
export const WRITER_FAVORITE = writer_id =>
  `${BASE_API_URL}/writers/${writer_id}/notifications/favorite/`
