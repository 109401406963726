import { fromJS } from 'immutable'

import {
  SET_COLLECTION,
  SET_SPECIFIC_COLLECTION,
  SET_SEARCH,
  SET_TRIGGER,
  RESET_STATE
} from './constants'

export const initialState = fromJS({
  specificCollection: '',
  collection: {
    webImage: {
      _url: ''
    },
    backColor: [255, 255, 255]
  },
  cardsSearched: '',
  trigger: ''
})


export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_STATE:
      return initialState

    case SET_COLLECTION:
      return state.set('collection', fromJS(action.collection))

    case SET_SPECIFIC_COLLECTION:
      return state.set('specificCollection', action.collection)

    case SET_SEARCH:
      return state.set('cardsSearched', action.value)

    case SET_TRIGGER:
      return state.set('trigger', action.value)

    default:
      return state
  }
}
