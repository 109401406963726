import { fromJS } from 'immutable'

import {
  FETCH_INITIAL_DATA,
  UPDATE_PROJECT,
  UPDATE_PHOTOS,
  UPDATE_CARDS,
  REMOVE_CARD,
  UPDATE_NOTES,
  ADD_CARD,
  REMOVE_PHOTO,
  ADD_PHOTO,
  UPDATE_PRICES,
  RESET_STATE,
  FETCH_SENDERS
} from './constants'

export const initialState = fromJS({
  cards: [],
  cardsToDelete: [],
  cardsToAdd: [],
  isLoading: true,
  maximum: {
    extraLimit: 270,
    extraCharacterPrice: 1.5,
    maxChars: 400,
    maxEmojis: 4,
    photoMax: 6
  },
  notes: [],
  photos: [],
  photosToDelete: [],
  photosToAdd: [],
  prices: {
    initial: 7.75
  },
  project: {
    deliveryDateTest: new Date(),
    prepDate: null,
    deliveryRule: 'send_now',
    accent_colors: [],
    audience_type: null,
    more_style_options: [],
    shouldIncludeSpecificDoodle: false,
    specificDoodle: ''
  },
  projectShare: null,
  senders: []
})

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_STATE:
      return initialState
    case FETCH_INITIAL_DATA:
      const initialData = fromJS({
        cards: action.cards,
        isLoading: false,
        notes: action.notes,
        photos: action.photos,
        project: action.project,
        projectShare: action.projectShare
      })
      return state.merge(initialData)

    case UPDATE_PRICES:
      return state.merge(fromJS({
        prices: {
          ...state.toJS().prices,
          ...action.prices
        }
      }))

    case UPDATE_PROJECT:
      return state.merge(fromJS({
        isLoading: false,
        project: {
          ...state.toJS().project,
          ...action.project
        }
      }))

    case UPDATE_PHOTOS:
      return state.set('photos', action.photos)
    case REMOVE_PHOTO:
      return state.set('photosToDelete', [action.id, ...state.toJS().photosToDelete])
    case ADD_PHOTO:
      return state.set('photosToAdd', [action.photo, ...state.toJS().photosToAdd])
    case UPDATE_CARDS:
      return state.set('cards', action.cards)
    case REMOVE_CARD:
      return state.merge(fromJS({
        cardsToDelete: {
          ...state.toJS().cardsToDelete,
          ...action.id
        }
      }))
    case ADD_CARD:
      return state.merge(fromJS({
        cardsToAdd: {
          ...state.toJS().cardsToAdd,
          ...action.id
        }
      }))
    case UPDATE_NOTES:
      return state.set('notes', action.notes)
    case FETCH_SENDERS:
      return state.set('senders', action.senders)
    default:
      return state
  }
}
