//
// -- Notification Component -- //
//
// Created by Jonatan Santa Cruz in 2017.
// Copyright 2023 Punkpost Inc.
//


// Base
import React from 'react'
import BaseComponent from 'utils/BaseComponent'

// Constant
const ICON_CANCEL = require('img/icons/cancel-white.png')


export default class Notification extends BaseComponent {
  //
  // -- Render -- //
  //

  render() {
    return this.props.isVisible ? (
      <div className="Signup__Notification">
        <p>{this.props.message}</p>
        <button onClick={this.props.onClose}>
          <img src={ICON_CANCEL} alt="x"/>
        </button>
      </div>
    ) : null
  }
}
