//
// -- FETCH Cards -- //
//


// Base
import Parse from 'parse'

// Reference Parse Objects
const Cards = new Parse.Object('Cards')
const CardCollectionItem = new Parse.Object('CardCollectionItem')
const Collection = Parse.Object.extend('Collection')
const Price = new Parse.Object('Price')


export const getCardItemsByCollectionId = async (collectionId,
  limit = null, relationship = null, tone = null
) => {
  if (!collectionId) {
    return null
  }
  const collection = new Collection()
    .set('objectId', collectionId)

  const innerQuery = new Parse.Query(Cards)
    .notEqualTo('isActive', false)
    .notEqualTo('isCancelled', true)
    .equalTo('status', true)
    if (relationship) {
      innerQuery.contains('relationships', relationship.toLowerCase(), 'i')
    }
    if (tone) {
      innerQuery.contains('tone', tone.toLowerCase(), 'i')
    }

  const query = new Parse.Query(CardCollectionItem)
    .equalTo('collection', collection)
    .matchesQuery('card', innerQuery)
    .notEqualTo('is_active', false)
    .ascending('position')
    .include([
      'card', 'card.price', 'card.cardArtist',
      'card.similarCollection', 'card.holiday'
    ])

  if (limit) {
    query.limit = limit
  }
  return await query.find()
}


export const getCardsByName = name => {
  const query = new Parse.Query(Cards)
    .equalTo('status', true)
    .notEqualTo('isCancelled', true)
    .notEqualTo('isPrivate', true)
    .notEqualTo('isActive', false)
    .matches('cardName', name, 'i')
    .include([
      'price', 'cardArtist',
      'similarCollection', 'holiday'
    ])
  return query.find()
}


/**
  * Retrive Card Data by id
  * @function getCardDataById
  * @param {string} id id of card
  * @returns {array} Array with Parse Objects results (If the id exist only get 1 element)
*/
export const getCardDataById = id => {
  if (!id) {
    return null
  }
  const query = new Parse.Query(Cards)
    .equalTo('objectId', id)
    .notEqualTo('isCancelled', true)
    .notEqualTo('isPrivate', true)
    .notEqualTo('isActive', false)
    .include([
      'price', 'cardArtist',
      'similarCollection', 'holiday'
    ])

  return query.first()
}


/**
  * Retrive Card Data by slug_name
  * @function getCardDataBySlugName
  * @param {string} slug_name of card
  * @returns {array} Array with Parse Objects results (If the id exist only get 1 element)
**/
export const getCardDataBySlugName = slug_name => {
  const query = new Parse.Query(Cards)
    .ascending('createdAt')
    .equalTo('slug_name', slug_name)
    .notEqualTo('isPrivate', true)
    .notEqualTo('isActive', false)
    .include([
      'price', 'cardArtist',
      'similarCollection', 'holiday'
    ])

  return query.first()
}


/**
  * Get Price Data by id.
  * @function getPriceDataById
  * @param {string} id id of price
  * @returns {array} Array with Parse Objects results (If the id exist only get 1 element)
**/
export const getPriceDataById = id => {
  if (!id) {
    return null
  }
  const query = new Parse.Query(Price)
  return query.get(id)
}


/**
  * Verify card is available.
  * @function verifyCardIsAvailable
  * @param {string} id id of price
  * @returns {bool} Boolean true if card is available, false if card is sold out.
**/
export const verifyCardIsAvailable = id => {
  return new Promise((resolve, reject) => {
    if (!id) {
      reject(null)
      return
    }
    new Parse.Query(Cards)
      .get(id)
      .catch(error => {
        reject(error)
        return
      })
      .then(object => {
        if (!object) {
          reject(null)
          return
        }
        const card = object.getAttributes()
        resolve(card.status)
      })
  })
}
