//
// -- Stories SubRoutes -- //
//
// Created by Satiago Prieto on 2018
// Copyright 2023 Punkpost Inc.
//


// Dependencies
import { lazy } from 'react'

// Containers
const App = lazy(() => import(
  /* webpackChunkName: "App" */
  'containers/App'
))

// Scenes
// const StoriesDetail = lazy(() => import(
//   /* webpackChunkName: "StoriesDetail" */
//   'scenes/Stories/Detail'
// ))
const Search = lazy(() => import(
  /* webpackChunkName: "StoriesSearch" */
  'scenes/Stories/Search'
))


// Routes
export default {
  searching: {
    path: '/search',
    container: App,
    loginRequired: false,
    component: Search
  }
  // ,
  // detail: {
  //   path: '/:slug_name',
  //   container: App,
  //   loginRequired: false,
  //   component: StoriesDetail
  // }
}
