// -- FavoriteCards Reducer -- //

// Base
import { fromJS } from 'immutable'

// Constants
import {
  RESET_DATA,
  UPDATE_FAVORITE_CARDS,
  SET_FAVORITE_CARD,
  REMOVE_FAVORITE_CARD
} from './types'


/**
  * @constant Immutable initial state
  * (https://facebook.github.io/immutable-js/docs/#/fromJS)
**/
export const initialState = fromJS({
  favoriteCardIds: []
})


/**
  * Reducer of account data
  * @param {state} state of reducer
  * (https://facebook.github.io/immutable-js/docs/#/set)
  * (https://facebook.github.io/immutable-js/docs/#/merge)
  * @param {string} action for account reducer (https://redux.js.org/docs/basics/Actions.html)
  * @return {state} new state of reducer
**/
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_DATA:
      return state.set('favoriteCardIds', [])

    case UPDATE_FAVORITE_CARDS:
      return state.set('favoriteCardIds', action.favoriteCardIds)

    case SET_FAVORITE_CARD:
      return state.set('favoriteCardIds', [...state.get('favoriteCardIds'), action.cardId])

    case REMOVE_FAVORITE_CARD:
    // if exists then remove it from deleted items.
      const indexOf = state.get('favoriteCardIds').indexOf(action.cardId)
      if (indexOf >= 0) {
        return state.set('favoriteCardIds', state.get('favoriteCardIds').remove(indexOf))
      }
      return state.set('favoriteCardIds', [])

    default:
      return state
  }
}
