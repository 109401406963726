import { fromJS } from 'immutable'

import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from './constants'


/**
  * Immutable initial state (https://facebook.github.io/immutable-js/docs/#/fromJS)
*/
export const initialState = fromJS({
  visible: 'none',
  text: '',
  type: ''
})

/**
  * Reducer of Notification Component
  * @param {state} state of reducer
  (https://facebook.github.io/immutable-js/docs/#/set)
  (https://facebook.github.io/immutable-js/docs/#/merge)
  * @param {string} action for account reducer (https://redux.js.org/docs/basics/Actions.html)
  * @return {state} new state of reducer
*/
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return state.merge(fromJS({
        visible: 'visible',
        text: action.text,
        type: action.notificationType
      }))
    case HIDE_NOTIFICATION:
      return state.merge(fromJS({ visible: 'none' }))
    default:
      return state
  }
}
