//
// -- SecondaryContainer for NewUser Main Landing Page-- //
//
// Created by Santiago Prieto on May 2022.
// Copyright 2023 Punkpost Inc.
//


// Base
import React from 'react'
import BaseComponent from 'utils/BaseComponent'
import './styles.less'

// Constants
const TITLE = `Your message, your creativity, your card.`
const SUBHEADER = `Our team handles the rest. Cards are mailed within 1 business day.`
const IMAGE = require('img/promotion/punkpost-explainer.png')
const IMAGE_WEBP = require('img/promotion/punkpost-explainer.webp')
const IMAGE_MOBILE = require('img/promotion/punkpost-explainer-mobile-large-text.png')
const IMAGE_MOBILE_WEBP = require('img/promotion/punkpost-explainer-mobile-large-text.webp')
const IMAGE_ALT = `You pick the card design, we write your message,
we mail your custom card.`


// Base Component
export default class SecondaryContainer extends BaseComponent {
  _renderHeroImage() {
    return (
      <div className="SecondaryContainer__Image">
        <picture
          onClick={this._handleHeroImageClick}
        >
          <source
            srcSet={`${IMAGE_MOBILE_WEBP} 1x`}
            media="(max-width: 550px)"
            type="image/webp"
          />
          <source
            srcSet={`${IMAGE_MOBILE} 1x`}
            media="(max-width: 550px)"
            type="image/png"
          />
          <source type="image/webp" srcSet={IMAGE_WEBP} />
          <img
            src={IMAGE}
            alt={IMAGE_ALT}
          />
        </picture>
      </div>
    )
  }

  render() {
    return (
      <div className="SecondaryContainer">
        <h2>{TITLE}</h2>
        {this._renderHeroImage()}
        <h3>{SUBHEADER}</h3>
      </div>
    )
  }
}
