//
// -- UPDATE Event -- //
//
// Created by Jonatan Santa Cruz on 2017.
// Copyright 2023 Punkpost Inc.
//


// Base
import Parse from 'parse'
import dayjs from 'dayjs'

// Constants
const Contact = Parse.Object.extend('Contacts')
const Event = Parse.Object.extend('Event')
const Post = Parse.Object.extend('Post')


/**
  * @function updateEvent
  * @param {string} event_id of event
  * @param {number} field of event
  * @param {number} value of event
  * @returns {Promise} event data
**/
export const updateEvent = (event_id, field, value) => {
  const _event = new Event()
    .set({
      objectId: event_id,
      [field]: value
    })

  return _event.save()
}


/**
  * @function updateEventWithPost
  * @param {string} event_id of event
  * @param {string} post_id of event
  * @returns {Promise} event data
**/
export const updateEventWithPost = (event_id, post_id) => {
  const post = new Post()
    .set('objectId', post_id)

  const _event = new Event()
    .set({
      objectId: event_id,
      workingPost: post
    })

  return _event.save()
}


/**
  * @function updateEventWithPostLastSentDate
  * @param {string} event_id of event
  * @param {string} post_id of event
  * @returns {Promise} event data
**/
export const updateEventWithPostLastSentDate = (event_id, post_id) => {
  const post = new Post()
    .set('objectId', post_id)

  const _event = new Event()
    .set({
      objectId: event_id,
      lastCardSent: dayjs().toDate(),
      workingPost: post_id ? post : null
    })
  return _event.save()
}

/**
  * @function updateEventDate
  * @param {string} event_id of event
  * @param {number} month of event
  * @param {number} day of event
  * @returns {Promise} event data
**/
export const updateEventDate = (event_id, month, day) => {
  const eventToUpdate = new Event()
    .set({
      objectId: event_id,
      day: day,
      month: month
    })

  return eventToUpdate.save()
}


/**
  * @function deactivateEvent
  * @param {string} event_id of event
  * @param {boolean} isBirthday of event
  * @param {string} contact_id of event
  * @returns {Promise} event data
**/
export const deactivateEvent = (event_id, isBirthday = false, contact_id) => {
  const event = new Event()
    .set({
      objectId: event_id,
      is_active: false,
      should_notify: false
    })

  if (isBirthday === false || !contact_id) {
    return event.save()
  }
  return event.save()
    .catch(error => {
      console.warn(error)
      return new Promise((reject) => {
        reject(error)
      })
    })
    .then(() => {
      const existingContact = new Contact()
        .set({
          objectId: contact_id,
          birthday: null
        })

      return existingContact.save()
    })
}
