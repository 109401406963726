import { fromJS } from 'immutable'

import {
  FETCH_FORM_DATA,
  UPDATE_FORM_FIELD,
  RESET_FORM,
  UPDATE_BUTTON
} from './constants'

export const initialState = fromJS({
  formData: {
    id: null,
    contactName: '',
    email: '',
    jobTitle: '',
    birthDay: 'Day',
    birthMonth: 'Month',
    eventMonth: 'Month',
    eventDay: 'Day',
    company: '',
    contactAddress: '',
    contactCity: '',
    contactState: '',
    contactZip: '',
    country: '',
    unit: ''
  },
  buttonProps: {
    disabled: false
  }
})

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_FORM_DATA:
      const formData = action.formData
      return state.merge(fromJS({
        formData: {
          id: formData.id,
          company: formData.company,
          contactAddress: formData.contactAddress,
          unit: formData.unit,
          contactCity: formData.contactCity,
          contactName: formData.contactName,
          jobTitle: formData.jobTitle,
          contactState: formData.contactState,
          contactZip: formData.contactZip,
          country: formData.country,
          email: formData.email,
          birthDay: formData.birthDay,
          birthMonth: formData.birthMonth,
          eventMonth: formData.eventMonth,
          eventDay: formData.eventDay
        }
      }))

    case UPDATE_FORM_FIELD:
      return state.set('formData', fromJS(action.newFormData))

    case RESET_FORM:
      return initialState

    case UPDATE_BUTTON:
      return state.merge(fromJS({
        buttonProps: {
          disabled: action.status
        }
      }))

    default:
      return state
  }
}
