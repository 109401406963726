import Parse from 'parse'

import { PARSE_SERVER_URL, PARSE_JS_ID, PARSE_APP_ID } from 'constants'

/*
  Input: Array of Parse elements
  Output: Array of attributes of Parse element
*/
export const getAttributesArray = elements => {
  if (!elements) {
    return null
  }
  let _elements = []
  elements.forEach(element => {
    _elements = [..._elements, {
      id: element.id,
      ...element.attributes
    }]
  })
  return _elements
}

/**
  * Get attributes from Parse object, substitute to flatten()
  @func getAttributes
  @param {object} obj Parse object
  @return {object} With id and attributes
*/
const getAttributes = obj => {
  return {
    id: obj.id,
    ...obj.attributes
  }
}

Parse.Object.prototype.getAttributes = function () {
  return getAttributes(this)
}

/*
 * Initialize Parse SDK
 */
export const initialize = () => {
  Parse.initialize(PARSE_APP_ID, PARSE_JS_ID)
  Parse.serverURL = PARSE_SERVER_URL
}
