export const ABOUT_PUNKPOST = `Punkpost sends beautifully handwritten cards, mailed by artists for
you. Customers place orders via the Punkpost iOS app or online platform in the amount of time it
takes to send a text. From there, one of our 100+ U.S.-based handwriting artists get to work,
turning a typed message into a custom, handwritten design on the card of your choosing, and it’s
in the mail within 24 hours. Punkpost is the dashboard for creating and managing happy moments.
For more info or to start sending love today, visit Punkpost.com, follow us @PunkpostCo or
download the app for iOS.`


export const PRODUCT_LINKS = [
  {
    name: 'The Handwriting',
    url: 'https://punkpost.com/stories/punkpost-handwriting-style-options'
  },
  {
    name: 'Get the iOS App',
    url: `https://itunes.apple.com/app/apple-store/id939096403?pt=111313804&amp;ct=Footer&amp;mt=8`
  },
  {
    name: 'Browse Cards',
    url: 'https://punkpost.com/card-shop'
  },
  {
    name: 'Redeem Gift Certificate',
    url: 'https://punkpost.com/redeem'
  }
]

export const SUPPORT_LINKS = [
  {
    name: 'Help and FAQs',
    url: 'https://punkpost.co/help'
  },
  {
    name: 'Contact',
    url: 'https://punkpost.co/contact'
  }
]

export const LEGAL_LINKS = [
  {
    name: 'Terms of Service',
    url: 'https://punkpost.co/terms'
  },
  {
    name: 'Privacy Policy',
    url: 'https://punkpost.co/privacy'
  },
  {
    name: 'Cookie Policy',
    url: 'https://punkpost.co/legal/cookie-policy'
  }
]
